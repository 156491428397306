import * as React from 'react'

import {
  type PaletteProps,
  type SpacingProps,
  type TypographyProps,
} from '@material-ui/system'

import { HeaderStep } from './HeaderStep'
import { HeaderIconStyled } from './styles/HeaderIconStyled'
import { HeaderStyled } from './styles/HeaderStyled'
import { SubheaderStyled } from './styles/SubheaderStyled'

export interface IHeaderProps
  extends SpacingProps,
    TypographyProps,
    PaletteProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  /** Margin below when `true` */
  gutter?: boolean
  /**
   * An emphasized icon on top of the header
   * and center align the header
   */
  icon?: React.ReactNode
  id?: string
}

export interface ISubheaderProps
  extends SpacingProps,
    TypographyProps,
    PaletteProps {}

export const Header = ({
  as,
  icon,
  children,
  ...otherProps
}: React.PropsWithChildren<IHeaderProps>) => {
  return (
    <HeaderStyled as={as} icon={icon} {...otherProps}>
      {icon && <HeaderIconStyled>{icon}</HeaderIconStyled>}
      {children}
    </HeaderStyled>
  )
}

Header.Subheader = SubheaderStyled
Header.Step = HeaderStep

export { HeaderStyled, SubheaderStyled }
