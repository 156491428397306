import * as React from 'react'

import { DividerStyled, type IDividerStyledProps } from './styles/DividerStyled'

export interface IDividerProps extends Partial<IDividerStyledProps> {
  /**
   * This component does not accept any children.
   */
  children?: never
}

/**
 * Divider separates vertically stacked components with a horizontal line.
 */
export const Divider = ({ space = 1 }: IDividerProps) => (
  <DividerStyled space={space} />
)

Divider.displayName = 'Divider'
