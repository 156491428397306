import styled from 'styled-components'

export interface INavigationSublinkStyledProps {
  isOpen?: 1 | 0 | boolean
}

export const NavigationSubContainerStyled = styled.div<INavigationSublinkStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  display: ${(props) => {
    return props.isOpen ? 'block' : 'none'
  }};
`
