import { type Enum } from '@webapp/utils'

export const ORDER_ITEM_DURATION_UNITS = {
  YEARS: 0,
  MONTHS: 1,
  INDEFINITE: 2,
} as const
export type OrderItemDurationUnit = Enum<typeof ORDER_ITEM_DURATION_UNITS>

export const ORDER_ITEM_FREQUENCY_UNITS = {
  YEARLY: 0,
  MONTHLY: 1,
} as const
export type OrderItemFrequencyUnit = Enum<typeof ORDER_ITEM_FREQUENCY_UNITS>

export const ORDER_ITEM_INDEFINITE_UNITS = {
  INDEFINITE: 0,
  DEFINITE: 1,
} as const
export type OrderItemIndefiniteUnit = Enum<typeof ORDER_ITEM_INDEFINITE_UNITS>
