import { ImprovedImportTypes } from './ImprovedImportType'
import { type Enum } from '../../utils/enums'
export const ImportTypes = {
  ...ImprovedImportTypes,
  USER: 'USER',
  ROLE: 'ROLE',
  PERMISSION: 'PERMISSION',
  APPROVER: 'APPROVER',
  APPROVAL_ROUTING_GROUP: 'APPROVAL_ROUTING_GROUP',
  APPROVAL_ROUTING_GROUP_WITH_ADVANCED_TRIGGERS:
    'APPROVAL_ROUTING_GROUP_WITH_ADVANCED_TRIGGERS',
  ACCOUNT: 'ACCOUNT',
  BUDGET: 'BUDGET',
  PLANNED_BUDGET: 'PLANNED_BUDGET',
  VENDOR: 'VENDOR',
  CATALOG_ITEM: 'CATALOG_ITEM',
  CATALOG_PERMISSION: 'CATALOG_PERMISSION',
  BUDGET_CATEGORY: 'BUDGET_CATEGORY',
  BUDGETS_SETUP: 'BUDGETS_SETUP',
  ACCOUNT_CODE_LINK: 'ACCOUNT_CODE_LINK',
} as const

export type ImportType = Enum<typeof ImportTypes>
