import { Enum } from '../../utils/enums'

export const TransactionAuthorizationStatus = Enum(
  'pending',
  'closed',
  'reversed'
)
export type TransactionAuthorizationStatus = Enum<
  typeof TransactionAuthorizationStatus
>
