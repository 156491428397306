import { createSelector } from 'reselect'

import { initialState } from './reducers'
import {
  type IPayCardManagementState,
  type IPayCardManagementStoreState,
  type IPayCardManagementTabCount,
} from './types'

export const selectTabCount = (state: IPayCardManagementState) =>
  state.payCardManagement || initialState

export const makeSelectTabCount = () =>
  createSelector<
    IPayCardManagementState,
    IPayCardManagementStoreState,
    IPayCardManagementTabCount
  >(
    selectTabCount,
    (payCardManagementState) => payCardManagementState.tabCounts
  )

export const selectNewCardCreated = createSelector(
  selectTabCount,
  (payCardManagementState) => payCardManagementState.newCardCreated
)
