import { defineMessages } from 'react-intl'

const scope = `procurify.components.IntermediateLogin`

export default defineMessages({
  SSOLogin: {
    id: `${scope}.SSOLogin`,
    defaultMessage: 'Sign in with Single Sign On (SSO)',
  },
  basicAuthLogin: {
    id: `${scope}.basicAuthLogin`,
    defaultMessage: 'Sign in with username and password',
  },
  dividerCaption: {
    id: `${scope}.dividerCaption`,
    defaultMessage: 'Or',
  },
})
