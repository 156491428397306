import { display, flexbox, sizing, spacing } from '@material-ui/system'
import styled, { css } from 'styled-components'

import { type IFlexItemProps } from '../FlexItem'

export const FlexItemStyled = styled.div<IFlexItemProps>`
  box-sizing: border-box;

  ${(props) => {
    if (props.spacing <= 0) return {}
    const gutter = props.spacing * props.theme.spacing()
    return css`
      padding: ${props.theme.typography.pxToRem(gutter / 2)};
    `
  }}

  ${flexbox}
  ${sizing}
  ${spacing}
  ${display}
`
FlexItemStyled.displayName = 'FlexItemStyled'
