import {
  HeaderStyled,
  IconComponentStyled,
  StatusLabelStyled,
  TextStyled,
} from '@procurify/ui'
import styled from 'styled-components'

import { NavigationLinkContainerStyled } from './NavigationLinkContainerStyled'
import { NavigationLinkStyled } from './NavigationLinkStyled'
import { NavigationSubContainerStyled } from './NavigationSubContainerStyled'
import { NavigationSublinkStyled } from './NavigationSublinkStyled'

export const NavigationStyled = styled.nav`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  position: relative;
  grid-area: navigation;
  left: 0;
  background-color: ${(props) => props.theme.colors.offwhite};
  transition: ease width 0.1s;

  z-index: ${(props) => props.theme.zIndex.navigation};

  padding: ${(props) => props.theme.space.medium}
    ${(props) => props.theme.space.small};

  width: ${(props) =>
    props.theme.typography.pxToRem(props.theme.navigation.fullWidth)};

  ${NavigationLinkStyled} > ${StatusLabelStyled} {
    margin-left: ${(props) => props.theme.space.small};
  }

  /* temporary - remove with FT-5576 */
  #whats_new__procurify_payments > ${IconComponentStyled} {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
    width: ${(props) =>
      props.theme.typography.pxToRem(props.theme.navigation.collapsedWidth)};

    ${HeaderStyled} {
      text-align: center;
    }

    ${NavigationLinkContainerStyled} {
      ${NavigationLinkStyled} > ${TextStyled} {
        margin-top: ${(props) => props.theme.typography.pxToRem(-82)};
        position: absolute;
        width: ${(props) =>
          props.theme.typography.pxToRem(props.theme.navigation.fullWidth)};
        left: ${(props) =>
          props.theme.typography.pxToRem(
            props.theme.navigation.collapsedWidth
          )};
        z-index: 1;
        font-weight: ${(props) => props.theme.typography.fontWeightMedium};
        text-align: left;

        display: none;
      }

      /* temporary - remove with FT_4281_US_PAYMENT_RAILS */
      #whats_new__procurify_payments > ${IconComponentStyled} {
        display: flex;
      }

      ${StatusLabelStyled} {
        opacity: 0;
        display: none;
      }
      .nav-chevron {
        opacity: 0;
        display: none;
      }

      .badge-container {
        opacity: 0;
        display: none;
      }

      .chevron-container {
        opacity: 0;
        display: none;
      }

      .navigation-tooltip-trigger {
        display: block;
      }

      > #ap ~ ${NavigationSubContainerStyled} {
        margin-top: ${(props) => props.theme.typography.pxToRem(-200)};
      }

      > ${/* sc-selector */ NavigationLinkStyled}#ap > ${TextStyled} {
        margin-top: ${(props) => props.theme.typography.pxToRem(-400)};
      }

      ${NavigationSubContainerStyled} {
        display: none;
        margin-top: ${(props) =>
          props.theme.typography.pxToRem(
            -props.theme.navigation.sublinkHeight
          )};
        position: absolute;
        width: ${(props) =>
          props.theme.typography.pxToRem(props.theme.navigation.fullWidth - 8)};
        left: ${(props) =>
          props.theme.typography.pxToRem(
            props.theme.navigation.collapsedWidth - 8
          )};
        padding-left: ${(props) => props.theme.typography.pxToRem(8)};

        ${/* sc-selector */ NavigationSublinkStyled}:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }

      &.is-collapsed-open {
        &.has-navigation-subs
          ${/* sc-selector */ NavigationLinkStyled}
          > ${TextStyled} {
          display: block;
          z-index: 2;
        }

        ${NavigationSubContainerStyled} {
          display: block;
          z-index: 2;
        }
      }

      &:hover {
        &.has-navigation-subs
          ${/* sc-selector */ NavigationLinkStyled}
          > ${TextStyled} {
          display: block;
          z-index: 3;
        }

        ${NavigationSubContainerStyled} {
          display: block;
          z-index: 3;
        }
      }
    }
  }

  @media print {
    display: none;
  }
`
