import { spacing, typography } from '@material-ui/system'
import { palette } from '@procurify/ui-theme'
import { rem } from 'polished'
import styled from 'styled-components'

import { type ISubheaderProps } from '../Header'

export const SubheaderStyled = styled.div<ISubheaderProps>`
  font-size: ${rem(16)};
  font-style: normal;
  font-weight: 400;
  ${spacing}
  ${typography}
  ${palette}
`
