import styled from 'styled-components'

export interface IDividerStyledProps {
  /**
   * Sets the height above and below the dividing line. If less than `0` this
   * value is assumed to be `1`.
   */
  space: number
}

const getMargin = ({ space }) => (space >= 0 ? space : 1)

export const DividerStyled = styled.hr<IDividerStyledProps>`
  margin: ${getMargin}rem 0;
  border: none;
  border-top: 1px solid ${(props) => props.theme.colors.content.border};
`
