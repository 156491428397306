import * as React from 'react'

import Linkify from 'react-linkify'

export const formatComment = (comment: string): React.ReactElement => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={decoratedHref}
          key={key}
        >
          {decoratedText}
        </a>
      )}
    >
      {comment}
    </Linkify>
  )
}

export function useFormatComment() {
  return (comment: string): React.ReactElement => {
    return formatComment(comment)
  }
}
