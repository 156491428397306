import { type Components } from '@webapp/api/integrations/client'
import { type Enum } from '../../utils/enums'

export type IntegrationCodatConfig = Exclude<
  Components.Schemas.IntegrationRequest['config'],
  Components.Schemas.NetSuiteConfig
>

// API spec returns more platforms than there are supported
// remove from exclude if you're adding the support for that platform
export type IntegrationServicePlatform =
  Components.Schemas.IntegrationRequest['name']

const PLATFORMS = {
  QUICKBOOKS_ONLINE: 'QUICKBOOKS_ONLINE',
  QUICKBOOKS_ONLINE_SANDBOX: 'QUICKBOOKS_ONLINE_SANDBOX',
  SAGE_INTACCT: 'SAGE_INTACCT',
  NETSUITE: 'NETSUITE',
  CODAT_NETSUITE: 'CODAT_NETSUITE',
  PROCURIFY: 'PROCURIFY',
  DYNAMICS_365_BUSINESS_CENTRAL: 'DYNAMICS_365_BUSINESS_CENTRAL',
  CODAT_SANDBOX: 'CODAT_SANDBOX',
  XERO: 'XERO',
} as const

const produceIntegrationPlatform = <
  T extends Record<keyof typeof PLATFORMS, IntegrationServicePlatform>,
>(
  x: T
) => x

export const IntegrationServicePlatforms = produceIntegrationPlatform({
  [PLATFORMS.QUICKBOOKS_ONLINE]:
    'urn:integration:backend:codat-quickbooks-online',
  [PLATFORMS.QUICKBOOKS_ONLINE_SANDBOX]:
    'urn:integration:backend:codat-quickbooks-online-sandbox',
  [PLATFORMS.SAGE_INTACCT]: 'urn:integration:backend:codat-sage-intacct',
  [PLATFORMS.NETSUITE]: 'urn:integration:backend:netsuite',
  [PLATFORMS.CODAT_NETSUITE]: 'urn:integration:backend:codat-netsuite',
  [PLATFORMS.PROCURIFY]: 'urn:integration:backend:procurify',
  [PLATFORMS.DYNAMICS_365_BUSINESS_CENTRAL]:
    'urn:integration:backend:codat-dynamics-365-business-central',
  [PLATFORMS.XERO]: 'urn:integration:backend:codat-xero',
  [PLATFORMS.CODAT_SANDBOX]: 'urn:integration:backend:codat-sandbox',
})

export const IntegrationLegacyPlatforms = {
  API: 'api',
  NETSUITE: 'netsuite',
  QUICKBOOKS: 'quickbooks',
  SLACK: 'slack',
  PUNCHOUT: 'punchout',
} as const

export type IntegrationLegacyPlatform = Enum<typeof IntegrationLegacyPlatforms>

export type IntegrationPlatform =
  | IntegrationLegacyPlatform
  | IntegrationServicePlatform
