import { Enum } from '../../utils/enums'

export const TravelStatusEnumValues = [
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied',
]
export const TravelStatus = Enum(
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied'
)
