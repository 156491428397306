import * as React from 'react'

import { AppHeaderLoader } from '@webapp/components/AppHeader/AppHeaderLoader'
import { NavigationLoader } from '@webapp/components/Navigation/components/NavigationLoader'
import { AppContentWrapper } from './components/AppContentWrapper'
import { AppStyled } from './styles/AppStyled'

export const AppLoader = () => {
  return (
    <AppStyled>
      <AppHeaderLoader />
      <AppContentWrapper>
        <NavigationLoader />
      </AppContentWrapper>
    </AppStyled>
  )
}
