import * as React from 'react'

import { StatusLabelStyled } from '@procurify/ui'
import { TagStyled } from '@procurify/ui'
import { TextStyled } from '@procurify/ui'
import { darken, tint } from 'polished'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export interface INavigationSublinkStyledProps {
  id: string
  to: string
  legacy: boolean
  className: string
  onClick?: () => void
}

export const NavigationSublinkStyled = styled(
  ({
    id,
    to,
    legacy,
    className,
    children,
    onClick,
  }: React.PropsWithChildren<INavigationSublinkStyledProps>) => {
    return legacy ? (
      <a id={id} className={className} href={to} onClick={onClick}>
        {children}
      </a>
    ) : (
      <NavLink
        id={id}
        to={to}
        className={className}
        activeClassName='selected'
        onClick={onClick}
      >
        {children}
      </NavLink>
    )
  }
)`
  && {
    font-size: ${(props) => props.theme.typography.pxToRem(14)};
    color: ${(props) => props.theme.colors.text.primary};

    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    width: 100%;
    min-height: ${(props) =>
      props.theme.typography.pxToRem(props.theme.navigation.sublinkHeight)};
    padding-left: ${(props) => props.theme.typography.pxToRem(36)};
    padding-right: ${(props) => props.theme.space.small};
    position: relative;

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      color: ${(props) => props.theme.colors.text.primary};
      background-color: ${(props) => darken(0.05, props.theme.colors.offwhite)};
    }

    &.active {
      background-color: ${(props) => props.theme.colors.selected};
    }

    ${TextStyled} {
      font-size: ${(props) => props.theme.typography.pxToRem(14)};

      margin-top: 0;
      margin-bottom: 0;

      + ${TextStyled} {
        margin-left: auto;
      }
    }

    &:focus-visible {
      z-index: 1;
      outline: ${(props) =>
        `${props.theme.typography.pxToRem(2)} solid ${
          props.theme.colors.focus
        }`};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
      padding-left: ${(props) => props.theme.space.medium};
    }
  }

  ${StatusLabelStyled} {
    margin-left: 0.5em;
  }
`
