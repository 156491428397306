export enum PayRequestStatusEnum {
  PENDING = 0,
  APPROVED = 1,
  DENIED = 2,
}

export enum PayRequestStatusLabel {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
}
