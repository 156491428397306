import { fontSize, type TypographyProps } from '@material-ui/system'
import styled from 'styled-components'

import { type StatusLabelStatus } from '../StatusLabel'
import { IconStyled } from '@ui/elements/Icon'
import { IconComponentStyled } from '@ui/elements/Icon/styles/IconComponentStyled'

interface IStatusLabelStyledProps extends Pick<TypographyProps, 'fontSize'> {
  type: StatusLabelStatus
}

export const StatusLabelStyled = styled.span<IStatusLabelStyledProps>`
  ${fontSize}

  line-height: 1;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.fontFamily.body};
  padding: ${(props) =>
    `${props.theme.space.xsmall} ${props.theme.space.medium}`};

  display: inline-flex;
  align-items: center;

  border-radius: ${(props) => props.theme.typography.pxToRem(100)};

  color: ${(props) => props.theme.colors.statusLabel[props.type].content};

  background: ${(props) =>
    props.theme.colors.statusLabel[props.type].background};

  > ${/* sc-selector */ IconStyled}, > ${IconComponentStyled} {
    transform: scale(0.75);
    margin-right: ${(props) => props.theme.space.xsmall};
  }

  text-wrap: nowrap;
`
