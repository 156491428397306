import Decimal from 'decimal.js'

import { type BudgetGroupBudget } from '@webapp/models'
import { DEFAULT_FORMAT_OPTIONS } from './constants'
import {
  type BudgetSubtotalsFormatted,
  type BudgetSubtotalTypes,
  type BudgetSubtotalsToSpendMap,
} from './types'
import { useFormatBudgetValues } from './useFormatBudgetValues'

export const useFormatBudgetSubtotals = (options = DEFAULT_FORMAT_OPTIONS) => {
  const mergeOptions = { ...DEFAULT_FORMAT_OPTIONS, ...options }
  const { formatBudgetPercent, formatBudgetPrice } =
    useFormatBudgetValues(mergeOptions)

  return (budget: BudgetGroupBudget): BudgetSubtotalsFormatted => {
    if (!budget) {
      return
    }

    const subtotals = calculateDecimalAmounts(budget)

    return {
      id: budget.id,
      isOverBudget: subtotals.total && subtotals.remaining.isNegative(),
      ...formatSubtotalAmounts(
        subtotals,
        formatBudgetPercent,
        formatBudgetPrice
      ),
    }
  }
}

type BudgetSubtotalsToDecimalMap = Record<BudgetSubtotalTypes, Decimal>

const calculateDecimalAmounts = (
  budget: BudgetGroupBudget
): BudgetSubtotalsToDecimalMap => {
  const total = new Decimal(budget.amount ?? 0)
  // calculate the amounts committed to the budget
  const approved = new Decimal(budget.approved ?? 0)
  const purchased = new Decimal(budget.purchased ?? 0)
  const billed = new Decimal(budget.invoiced ?? 0)
  const committed = approved.plus(purchased).plus(billed)
  // calculate the amounts remaining in the budget
  const remaining = new Decimal(budget.variance ?? 0)
  const pending = new Decimal(budget.pending ?? 0)
  const remainingMinusPending = remaining.minus(pending)
  const spendRequest = new Decimal(budget.request_pending_amount ?? 0)
  const spendTotal = committed.plus(pending).plus(spendRequest)

  return {
    billed,
    approved,
    purchased,
    pending,
    remaining,
    committed,
    total,
    remainingMinusPending,
    spendRequest,
    spendTotal,
  }
}

type BudgetFormatValues = ReturnType<typeof useFormatBudgetValues>

const formatSubtotalAmounts = (
  subtotals: BudgetSubtotalsToDecimalMap,
  formatBudgetPercent: BudgetFormatValues['formatBudgetPercent'],
  formatBudgetPrice: BudgetFormatValues['formatBudgetPrice']
): BudgetSubtotalsToSpendMap => {
  const subtotalsList = {} as BudgetSubtotalsToSpendMap
  const isValidBudget = subtotals.total.greaterThan(0)

  Object.keys(subtotals).map((key) => {
    const decimal: Decimal = subtotals[key]
    const amount = decimal.toFixed()
    const percentage = isValidBudget
      ? decimal.dividedBy(subtotals.total)
      : undefined
    const percentageRatio = percentage?.toFixed()
    const percentageNumber = percentage?.times(100).toDP(4).toNumber() ?? 0
    subtotalsList[key] = {
      amount: formatBudgetPrice(amount),
      percentage: formatBudgetPercent(percentageRatio),
      percentageNumber,
    }
  })
  return subtotalsList
}
