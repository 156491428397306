import styled from 'styled-components'

interface IAppHeaderLoaderStyledProps {}

export const AppHeaderLoaderStyled = styled.div<IAppHeaderLoaderStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  display: flex;
  grid-area: header;
  top: 0;
  left: 0;
  width: 100%;

  background-color: ${(props) => props.theme.colors.white};
  line-height: 1;
  height: ${(props) => props.theme.appHeader.height};

  padding: 0 ${(props) => props.theme.space.medium};

  position: sticky;
  z-index: 999;

  box-shadow: ${(props) => props.theme.shadows[4]};
`
