import * as React from 'react'

import { type ModalContext, ModalContextProvider } from './ModalContext'

export interface IModalProviderProps {}

export const ModalProvider: React.FC<IModalProviderProps> = React.memo(
  (props) => {
    const modalPromise = React.useRef<any>(null)

    const [modalContextValue, setModalContextValue] = React.useState<{
      component: ModalContext['component']
      componentProps: ModalContext['componentProps']
    }>({
      component: null,
      componentProps: {},
    })

    const hideModal = () => {
      setModalContextValue({
        component: null,
        componentProps: {},
      })

      modalPromise.current?.resolve()
    }

    const dismissModal = () => {
      setModalContextValue({
        component: null,
        componentProps: {},
      })

      modalPromise.current?.reject()
    }

    const showModal = (
      component: ModalContext['component'],
      componentProps: ModalContext['componentProps'] = {}
    ): Promise<any> => {
      setModalContextValue({
        component,
        componentProps,
      })

      return new Promise((resolve, reject) => {
        modalPromise.current = {
          resolve,
          reject,
        }
      })
    }

    return (
      <ModalContextProvider
        value={{
          ...modalContextValue,
          showModal,
          hideModal,
          dismissModal,
        }}
      >
        {props.children}
      </ModalContextProvider>
    )
  }
)

ModalProvider.defaultProps = {}
ModalProvider.displayName = 'ModalProvider'
