import { defineMessages } from 'react-intl'

const scope = `procurify.hooks.useFormatVendor`

export default defineMessages({
  otherPrefix: {
    id: `${scope}.otherPrefix`,
    defaultMessage: 'OTHER - ',
  },
})
