import { Decimal } from 'decimal.js'

/**
 *
 * @param {number | string} value The value to be formatted
 * @param {boolean} isPriceValue Sets rounding option to `ROUND_HALF_EVEN`, otherwise known as Banker's Rounding. This follows the IEEE-754 rounding standard also set out in Python's default round() function which also uses Banker's Rounding. Otherwise, default to `ROUND_HALF_UP`.
 * @returns {Decimal}
 */
export const formatDecimals = (
  value: number | string,
  isPriceValue?: boolean
): Decimal => {
  if (isPriceValue) {
    Decimal.set({ rounding: Decimal.ROUND_HALF_EVEN })
  } else {
    Decimal.set({ rounding: Decimal.ROUND_HALF_UP })
  }

  return new Decimal(value)
}
