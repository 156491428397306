import { type Enum } from '../../utils/enums'

export const AddressStringTypes = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
  VENDOR: 'VENDOR',
  GENERAL: 'GENERAL',
  USER: 'USER',
  TRAVEL: 'TRAVEL',
  PRIVATE: 'PRIVATE',
} as const

export const AddressTypes = {
  [AddressStringTypes.BILLING]: 1,
  [AddressStringTypes.SHIPPING]: 2,
  [AddressStringTypes.VENDOR]: 3,
  [AddressStringTypes.GENERAL]: 4,
  [AddressStringTypes.USER]: 5,
  [AddressStringTypes.TRAVEL]: 6,
  [AddressStringTypes.PRIVATE]: 7,
} as const

export type AddressType = Enum<typeof AddressTypes>
export type AddressStringType = Enum<typeof AddressStringTypes>
