import { type Enum } from '../../utils/enums'

export const ExportTypes = {
  ORDER_ITEMS: 0,
  PO_ITEMS: 1,
  EXPENSE_REPORT_HISTORY: 2,
  TRAVEL_HISTORY: 3,
  BUDGET: 4,
  RECEIVING_HISTORY: 5,
  CATALOG: 6,
  BILLS: 8,
  USERS: 12,
  LOCATIONS: 13,
  UNBILLED_PO_ITEMS: 14,
  PAY_TRANSACTIONS: 15,
  SPEND_CATEGORIES: 24,
  PAYMENTS: 30,
  AIRWALLEX_BALANCE_TRANSACTION: 31,
} as const

export type ExportType = Enum<typeof ExportTypes>
