import { type Enum } from '../../utils/enums'

export const SpendingCardDeclinedTransactionReasons = {
  OTHER: 0,
  INSUFFICIENT_FUNDS: 1,
  UNSUPPORTED_CURRENCY: 2,
  FOREIGN_VENDOR: 3,
  LOCKED_CARD: 4,
  CANCELED_CARD: 5,
  VERIFICATION_FAILED: 6,
  SUSPECTED_FRAUD: 7,
} as const

export type SpendingCardDeclinedTransactionReason = Enum<
  typeof SpendingCardDeclinedTransactionReasons
>
