import { type Tax } from '@webapp/models'
import { ApiClient } from '../services/api'

export const TAX_URL = '/api/v3/tax'

export interface CreateTaxSubmission {
  name: Tax['name']
  percentage: Tax['percentage']
  description?: Tax['description']
  is_inclusive: Tax['is_inclusive']
}

export class TaxAPI {
  static readonly getActiveTaxes = (params?) => {
    return ApiClient.get<{}, Tax[]>(`${TAX_URL}/`, {
      params: {
        is_active: 'True',
        order_by: 'name',
        ...params,
      },
    })
  }

  static readonly getTax = (taxId: Tax['id']) => {
    return ApiClient.get<{}, Tax>(`${TAX_URL}/${taxId}/`)
  }

  static readonly createTax = (data: CreateTaxSubmission) => {
    return ApiClient.post<CreateTaxSubmission, Tax>(`${TAX_URL}/`, data)
  }
}
