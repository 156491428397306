import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

export const useSpendInsightsVisualNames = () => {
  const intl = useProcurifyIntl()

  return {
    SPEND_TREND: intl.formatMessage(messages.spendTrendName),
    TOP_5_ITEMS: intl.formatMessage(messages.top5ItemsName),
    TOP_5_VENDORS: intl.formatMessage(messages.top5VendorsName),
    PAYMENT_TERMS_BY_COMMITTED_SPEND: intl.formatMessage(
      messages.paymentTermsByCommittedSpendName
    ),
    TOP_5_DEPARTMENTS: intl.formatMessage(messages.top5DepartmentsName),
    APPROVED_RFO_VALUES: intl.formatMessage(messages.rejectedRFOValuesName),
    APPROVED_RFOS: intl.formatMessage(messages.approvedRFOsName),
    CREATED_POS: intl.formatMessage(messages.createdPOsName),
    REJECTED_RFOS: intl.formatMessage(messages.rejectedRFOsName),
    REJECTED_RFO_VALUES: intl.formatMessage(messages.rejectedRFOValuesName),
  }
}
