import { type Enum } from '../../utils/enums'

export const BillStatusEnumValues = [
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied',
  'Payment Confirmed',
  'Payment Approved',
  'Paid',
]

export const BillStatuses = {
  DRAFT: 0,
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
  PAID: 4,
  WITHDRAWN: 5,
  /**
   * @deprecated Not applicable in APV2
   */
  PAYMENT_CONFIRMED: 6,
  /**
   * @deprecated Not applicable in APV2
   */
  PAYMENT_APPROVED: 7,
  /**
   * @deprecated Not applicable in APV2
   */
  LIMBO: 8,
} as const

export type BillStatus = Enum<typeof BillStatuses>
