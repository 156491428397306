import { spacing, type SpacingProps, typography } from '@material-ui/system'
import styled from 'styled-components'

import { type IIconProps } from '../Icon'

function iconSize(size) {
  if (typeof size === 'number') {
    return `${size}px`
  }

  return `12px`
}

export interface IIconStyledProps extends Partial<IIconProps>, SpacingProps {
  icon?: any
}

export const IconStyled = styled.i<IIconStyledProps>`
  && {
    color: ${(props) =>
      props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.text.primary};
    ${(props) => props.size && `font-size: ${iconSize(props.size)}`};
    ${typography};
    ${spacing};
    ${(props) => props.bold && `font-weight: ${props.theme.fontWeights.bold}`};
  }
`
