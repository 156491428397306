import { type Enum } from '@webapp/utils'

export const PurchaseOrderStatuses = {
  OPEN: 0,
  CANCELLED: 1,
  CLOSED: 3,
  PAID: 4,
  REOPEN: 5,
} as const

export type PurchaseOrderStatus = Enum<typeof PurchaseOrderStatuses>
