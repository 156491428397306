import React from 'react'

import { FlexItem } from './FlexItem'
import { FlexRow } from './FlexRow'
import { FlexStyled } from './styles/FlexStyled'

export interface IFlexProps {
  children?: React.ReactNode
  /**
   * Sets the outer gutter space around all four sides of the component.
   */
  spacing?: number
}

/**
 *
 * Please use `<Space />` whenever possible to handle standard cases of arranging components with preset spacing.
 *
 */
export const Flex = ({ children, spacing = 1 }: IFlexProps) => {
  return <FlexStyled spacing={spacing}>{children}</FlexStyled>
}

Flex.Item = FlexItem
Flex.Row = FlexRow

Flex.displayName = 'Flex'
