import { useSelector } from 'react-redux'

import { PageUrls } from '@webapp/config/app/app.urls'
import { type RoutePathBuilder, type UrlBuilder } from './types'
import { makeSelectFeatureFlags } from '../../stores/global'

const stripTrailingSlash = (url: string) => url.replace(/^(.+?)\/*?$/, '$1')

/**
 * This is a copy of the PageUrls object, we need a copy of it because
 * RoutePathBuilders shouldn't be affected by feature flags. When we
 * feature flag urls, we only apply it to `pageUrlBuilders`.
 */
const RoutePathBuilders = {
  ...PageUrls,
}

export const useAppRoutes = () => {
  const featureFlags = useSelector(makeSelectFeatureFlags())

  const pageUrlBuilders = {
    ...PageUrls,
  }

  /**
   * This function will build the URL path to the specific page requested.
   *
   * This function should be used when the application needs to redirect the user
   * to another page.
   *
   * Example:
   * ```tsx
   * <Link to={url('DashboardPage')} />
   * // or
   * window.location.replace(url('DashboardPage'))
   * ```
   *
   * @param page The name of the page
   * @param params (Optional) The params required for the page
   * @returns string path of the page requested
   */
  const url: UrlBuilder = <P extends keyof typeof PageUrls>(
    page: P,
    ...params
  ) => {
    if (featureFlags.FT_5817_ENABLE_BUDGETS_REDESIGN) {
      page = page.replace('BudgetCategory', 'Budgets') as P
    }

    return stripTrailingSlash(
      pageUrlBuilders[page](...(params as [never]))
    ) as ReturnType<(typeof PageUrls)[P]>
  }

  /**
   * This function will build the Route path for a page
   *
   * This function should only be used to define a route path
   * for our Router
   *
   * Example:
   * ```tsx
   * // /bills/requests/:status
   * <Route path={rp('BillListPage', { status: ':status' })} />
   * ```
   *
   * @param page The name of the page
   * @param params (Optional) The params required for the page
   * @returns string
   */
  const brp: RoutePathBuilder = <P extends keyof typeof PageUrls>(
    page: P,
    ...params
  ) => {
    if (featureFlags.FT_5817_ENABLE_BUDGETS_REDESIGN) {
      page = page.replace('BudgetCategory', 'Budgets') as P
    }

    return RoutePathBuilders[page](...(params as [never])) as ReturnType<
      (typeof PageUrls)[P]
    >
  }

  return {
    url,
    brp,
  }
}
