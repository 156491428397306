import { type Enum } from '@webapp/utils'

export const ContractRenewalTerms = {
  YEAR: 0,
  MONTH: 1,
  WEEK: 2,
  DAY: 3,
} as const

export type ContractRenewalTerm = Enum<typeof ContractRenewalTerms>
