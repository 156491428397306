import { Enum } from '../../utils/enums'

export const PayCardTransactionStatus = Enum(
  'capture',
  'precapture',
  'refund',
  'cash withdrawal',
  'refund reversal',
  'dispute',
  'dispute loss'
)
export type PayCardTransactionStatus = Enum<typeof PayCardTransactionStatus>
