import styled from 'styled-components'

interface ISheetStyledProps {}

export const SheetStyled = styled.div<ISheetStyledProps>`
  position: fixed;

  /* TODO: add prop to control positioning should be able to open from bottom of
  screen, or the opposite of the navigation the sheet should never open from the same
  side that the nav is on i.e. if a Nav menu is on the left then the Sheet should only
  open from the right as per Material Design guidelines. The border should
  also change depending on which side the Sheet is opened from. */
  top: 0;
  right: 0;

  background-color: ${(props) => props.theme.colors.content.contentBackground};
  border-left: 1px solid ${(props) => props.theme.colors.content.border};

  max-width: ${(props) => props.theme.typography.pxToRem(400)};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`
