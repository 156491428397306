import { type Enum } from '@webapp/utils'

export const PaymentMethodTypes = {
  OTHER: 0,
  CHECK: 1,
  ACH: 2,
  EFT: 3,
  WIRE: 4,
  PROCURIFY_CHECK: 5,
  PROCURIFY_ACH: 6,
  PROCURIFY_WIRE: 7,
} as const

export type PaymentMethodType = Enum<typeof PaymentMethodTypes>

export const FinancialAccountPaymentMethodTypes: PaymentMethodType[] = [
  PaymentMethodTypes.EFT,
  PaymentMethodTypes.PROCURIFY_ACH,
  PaymentMethodTypes.PROCURIFY_WIRE,
  PaymentMethodTypes.PROCURIFY_CHECK,
]
