import { em } from 'polished'
import styled from 'styled-components'

import { type IHeaderStepProps } from '../HeaderStep'

interface IHeaderStepStyledProps extends Pick<IHeaderStepProps, 'color'> {}

export const HeaderStepStyled = styled.div<IHeaderStepStyledProps>`
  width: ${em(20)};
  height: ${em(20)};
  line-height: ${em(22)};

  border-radius: 50%;
  box-shadow: 0 0 0 ${em(1)}
    ${(props) =>
      props.theme.colors?.[props.color] || props.theme.colors.primary};

  color: ${(props) =>
    props.theme.colors?.[props.color] || props.theme.colors.primary};
  background-color: transparent;

  text-align: center;

  display: inline-block;

  margin-right: 0.5em;
`
