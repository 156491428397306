import styled, { css } from 'styled-components'

import { type PaletteProps } from '@ui/core/types'

export interface ICardHeaderStyledProps extends Pick<PaletteProps, 'bgcolor'> {}

export const CardHeaderStyled = styled.div<ICardHeaderStyledProps>`
  font-family: ${(props) => props.theme.fontFamily.header};
  font-weight: ${(props) => props.theme.fontWeights.semibold};

  ${(props) =>
    props.bgcolor &&
    css`
      background: ${props.theme.colors[props.bgcolor]};
    `}

  border-top-left-radius: ${(props) =>
    props.theme.box.borderRadius(props.theme.typography.htmlFontSize)};
  border-top-right-radius: ${(props) =>
    props.theme.box.borderRadius(props.theme.typography.htmlFontSize)};

  padding: 0
    ${(props) =>
      props.theme.box.horizontalPadding(props.theme.typography.htmlFontSize)};
  min-height: ${(props) => props.theme.typography.pxToRem(50)};

  display: flex;
  align-items: center;
`

CardHeaderStyled.displayName = 'CardHeaderStyled'
