import { type FormatNumberOptions, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { formatDecimals } from '@webapp/core/utils/formatDecimals'
import { makeSelectPriceDecimalPlaces } from '@webapp/stores/global'

interface UseFormatPriceOptions {
  mdp: boolean
  useSystemLocale: boolean
}

export const useFormatPrice = ({
  mdp = false,
  useSystemLocale = false,
}: Partial<UseFormatPriceOptions> = {}) => {
  const intl = useIntl()
  const priceDecimalPlaces = useSelector(makeSelectPriceDecimalPlaces())

  const formatNumber = (value: number, formatOptions: FormatNumberOptions) => {
    if (useSystemLocale) {
      return intl.formatNumber(value, formatOptions)
    } else {
      return new Intl.NumberFormat('en', formatOptions).format(value)
    }
  }

  return (
    price: string | number,
    fallbackValue: string = formatNumber(0, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  ) => {
    try {
      const decimalPlaces = mdp ? priceDecimalPlaces : 2

      const formatPrice = formatDecimals(price, true)
        .toDP(decimalPlaces)
        .toNumber()

      return formatNumber(formatPrice, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: decimalPlaces,
      })
    } catch (e) {
      return fallbackValue
    }
  }
}
