import styled from 'styled-components'

import { CircleLoader } from '@ui/elements/Loaders'

export const ButtonLoaderStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.disabled};

  > ${CircleLoader} {
    position: absolute;
  }
`
