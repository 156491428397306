import { FETCH_COUNTS, UPDATE_COUNT, UPDATE_COUNTS } from './constants'
import {
  type FetchCountsAction,
  type NavigationNotificationCount,
  type UpdateCountAction,
  type UpdateCountsAction,
} from './types'

export const updateCount = (
  key: UpdateCountAction['key'],
  count: UpdateCountAction['count']
): UpdateCountAction => ({ type: UPDATE_COUNT, key, count })

export const updateCounts = (
  counts: NavigationNotificationCount
): UpdateCountsAction => ({
  type: UPDATE_COUNTS,
  counts,
})

export const fetchCounts = (): FetchCountsAction => ({
  type: FETCH_COUNTS,
})
