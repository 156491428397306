import { type Enum } from '@webapp/utils/enums'

export const PaymentOutboundSyncStatuses = {
  FAILED_TO_INITIATE: 0,
  INITIATED_FOR_PROCESSING: 1,
  FAILED_TO_PROCESS: 2,
  POSTED_TO_VENDOR: 3,
  RETURNED_FROM_VENDOR: 4,
}

export type PaymentOutboundSyncStatus = Enum<typeof PaymentOutboundSyncStatuses>
