import * as React from 'react'

import { type IntlShape, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  makeSelectOrganizationalSegmentCustomLabels,
  makeSelectOrganizationalSegmentLabel,
  PRIMARY_ORG_CATEGORY_LABELS,
  SECONDARY_ORG_CATEGORY_LABELS,
} from '@webapp/stores/global'
import { useFeatureFlag } from '../useFeatureFlag'

const richTextHtmlHelpers = {
  strong: (str) => <strong>{str}</strong>,
  br: <br />,
  asterisk: <span style={{ color: '#D14229' }}>*</span>,
  italic: (str) => <i>{str}</i>,
}

export const useProcurifyIntl = (): IntlShape => {
  const { formatMessage, ...defaultIntl } = useIntl()

  const enableOrganizationalCategoryLabels = useSelector(
    makeSelectOrganizationalSegmentCustomLabels()
  )

  const childOrganizationalCategoryLabel = useSelector(
    makeSelectOrganizationalSegmentLabel()
  )

  const procurifyFormatMessage: IntlShape['formatMessage'] = (
    descriptor,
    values
  ) => {
    return formatMessage(descriptor, {
      ...values,
      ...richTextHtmlHelpers,
      singularSecondaryOrgCategoryLabel: enableOrganizationalCategoryLabels
        ? childOrganizationalCategoryLabel.department.singular
        : SECONDARY_ORG_CATEGORY_LABELS.SINGULAR,
      pluralSecondaryOrgCategoryLabel: enableOrganizationalCategoryLabels
        ? childOrganizationalCategoryLabel.department.plural
        : SECONDARY_ORG_CATEGORY_LABELS.PLURAL,
      singularPrimaryOrgCategoryLabel: enableOrganizationalCategoryLabels
        ? childOrganizationalCategoryLabel.location.singular
        : PRIMARY_ORG_CATEGORY_LABELS.SINGULAR,
      pluralPrimaryOrgCategoryLabel: enableOrganizationalCategoryLabels
        ? childOrganizationalCategoryLabel.location.plural
        : PRIMARY_ORG_CATEGORY_LABELS.PLURAL,
    })
  }

  return {
    ...defaultIntl,
    formatMessage: procurifyFormatMessage,
  }
}
