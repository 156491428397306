export function getCSRFToken() {
  let cookieValue = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let cookie of cookies) {
      cookie = cookie.trim()
      if (cookie.substring(0, 10) === 'csrftoken' + '=') {
        cookieValue = decodeURIComponent(cookie.substring(10))
        break
      }
    }
  }
  return cookieValue
}
