import * as React from 'react'

export interface ITextLoaderProps {
  width?: string | number
  height?: number
  inverted?: boolean
}

export const TextLoader: React.FC<ITextLoaderProps> = React.memo(
  ({ width = '100%', height = 14, inverted = false, ...otherProps }) => {
    const clipID = Math.random().toString(36).substring(2)
    const gradientID = Math.random().toString(36).substring(2)

    const bgColorValue = inverted ? 255 : 0

    return (
      <svg
        width={width}
        height={height + 'px'}
        aria-label='Loading interface...'
        role='img'
      >
        <rect
          x='0'
          y='0'
          width={width}
          height={height}
          clipPath={`url(#${clipID})`}
          style={{ fill: `url(#${gradientID})` }}
        />
        <defs>
          <clipPath id={`${clipID}`}>
            <rect x='0' y='0' rx='4' ry='4' width={width} height={height} />
          </clipPath>
          <linearGradient id={`${gradientID}`}>
            <stop
              offset='0.14918'
              stopColor={`rgba(${bgColorValue},${bgColorValue},${bgColorValue},0.05)`}
              stopOpacity='1'
            >
              <animate
                attributeName='offset'
                values='-2; -2; 1'
                keyTimes='0; 0.25; 1'
                dur='2s'
                repeatCount='indefinite'
              />
            </stop>
            <stop
              offset='1.14918'
              stopColor={`rgba(${bgColorValue},${bgColorValue},${bgColorValue},0.1)`}
              stopOpacity='1'
            >
              <animate
                attributeName='offset'
                values='-1; -1; 2'
                keyTimes='0; 0.25; 1'
                dur='2s'
                repeatCount='indefinite'
              />
            </stop>
            <stop
              offset='2.14918'
              stopColor={`rgba(${bgColorValue},${bgColorValue},${bgColorValue},0.05)`}
              stopOpacity='1'
            >
              <animate
                attributeName='offset'
                values='0; 0; 3'
                keyTimes='0; 0.25; 1'
                dur='2s'
                repeatCount='indefinite'
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    )
  }
)

TextLoader.defaultProps = {}
TextLoader.displayName = 'TextLoader'
