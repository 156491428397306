import { style } from '@material-ui/system'

import { type StyleFunctionWithOptionalProps } from './palette'

export interface TextEllipsisProps {
  textEllipsis?: any
}

export const textEllipsis = style({
  cssProperty: false,
  prop: 'textEllipsis',
  transform: (lineClamp = 1) => `
    display: -webkit-box;
    -webkit-line-clamp: ${lineClamp === true ? 1 : (lineClamp as number)};
    -webkit-box-orient: vertical;  
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
  `,
}) as StyleFunctionWithOptionalProps<'textEllipsis'>
