import { get } from 'lodash'
import { createSelector } from 'reselect'

import { type FeatureFlagKey } from '@webapp/services/FeatureFlag'
import {
  GLOBAL_STATE_KEY,
  PRIMARY_ORG_CATEGORY_LABELS,
  SECONDARY_ORG_CATEGORY_LABELS,
} from './constants'
import { initialState } from './reducers'
import { type IGlobalState } from './types'
import { type ContentType, type FeatureSwitches } from '../../models'

export const selectGlobal = (state: IGlobalState): IGlobalState =>
  state[GLOBAL_STATE_KEY] || initialState

export const makeSelectOrderCustomfields = () =>
  createSelector(selectGlobal, (globalState) => globalState.customfields.order)

export const makeSelectFeatureSwitches = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.featureSwitches || ({} as FeatureSwitches)
  )

export const makeSelectPriceDecimalPlaces = () =>
  createSelector(makeSelectFeatureSwitches(), (featureSwitches) => {
    if (featureSwitches.multiple_decimals_unit_price) {
      return 8
    } else {
      return 2
    }
  })

export const makeSelectQuantityDecimalPlaces = () =>
  createSelector(makeSelectFeatureSwitches(), (featureSwitches) => {
    if (featureSwitches.multiple_decimals_quantity) {
      return 5
    } else {
      return 2
    }
  })

export const makeSelectOrganizationalSegmentCustomLabels = () =>
  createSelector(
    makeSelectFeatureSwitches(),
    (featureSwitches) => featureSwitches.organizational_segments
  )

export const makeSelectUser = () =>
  createSelector(selectGlobal, (globalState) => globalState.user)

export const makeSelectDomain = () =>
  createSelector(selectGlobal, (globalState) => globalState.domain)

export const makeSelectSystemPreferences = () =>
  createSelector(selectGlobal, (globalState) => globalState.systemPreferences)

export const makeSelectUserDelegatee = () =>
  createSelector(makeSelectUser(), (userState) => userState.approval_delegatee)

export const makeSelectNavigationPermissions = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.navigationPermissions
  )

export const makeSelectPermissions = () =>
  createSelector(selectGlobal, (globalState) => globalState.permissions)

export const makeSelectContentTypes = () =>
  createSelector(selectGlobal, (globalState) => globalState.contenttypes)

export const makeSelectContentTypesByModel = (
  app: ContentType['app_label'],
  model: string
) =>
  createSelector(makeSelectContentTypes(), (contenttypes) =>
    get(contenttypes, `${app}__${model}`)
  )

export const makeSelectContentTypesByID = (id: number) =>
  createSelector(makeSelectContentTypes(), (contenttypes) => {
    for (const key in contenttypes) {
      if (contenttypes[key].id === id) return contenttypes[key]
    }
  })

export const makeSelectBaseCurrency = () =>
  createSelector(selectGlobal, (globalState) => globalState.baseCurrency)

export const makeSelectEndpoints = () =>
  createSelector(selectGlobal, (globalState) => globalState.endpoints)

export const makeSelectFeatureFlags = () =>
  createSelector(selectGlobal, (globalState) => globalState.featureFlags)

export const makeSelectFeatureFlag = (flag: FeatureFlagKey) =>
  createSelector(selectGlobal, (globalState) => globalState.featureFlags[flag])

export const makeSelectSegmentAnalyticsMetadata = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.segmentAnalyticsMetadata
  )

export const makeSelectIsPaySetUp = () =>
  createSelector(selectGlobal, (globalState) => globalState.isPaySetUp)

export const makeSelectSetupProgress = () =>
  createSelector(selectGlobal, (globalState) => globalState.setupProgress)

// This selector is render blocking the entire app, add to it sparingly
export const makeSelectIsAppReady = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.user?.id && globalState.hasFeatureFlagsResolved
  )

export const makeSelectRenderedBy = () =>
  createSelector(selectGlobal, (globalState) => globalState.renderedBy)

export const makeSelectUserProcurifyDomains = () =>
  createSelector(makeSelectUser(), (user) => user?.domains?.procurify_domains)

export const makeSelectDomainSwitchActive = () =>
  createSelector(makeSelectUser(), (user) => user?.domains?.active)

export const makeSelectOrganizationalSegmentLabel = () =>
  createSelector(
    makeSelectSystemPreferences(),
    (systemPreferences) =>
      systemPreferences?.['custom-labels'] || {
        location: {
          singular: PRIMARY_ORG_CATEGORY_LABELS.SINGULAR,
          plural: PRIMARY_ORG_CATEGORY_LABELS.PLURAL,
        },
        department: {
          singular: SECONDARY_ORG_CATEGORY_LABELS.SINGULAR,
          plural: SECONDARY_ORG_CATEGORY_LABELS.PLURAL,
        },
      }
  )

export const makeSelectApplicationStatus = () =>
  createSelector(selectGlobal, (globalState) => globalState.applicationStatus)

export const makeSelectPixHost = () =>
  createSelector(selectGlobal, (globalState) => globalState.endpoints.pix)

export const makeSelectRole = () =>
  createSelector(selectGlobal, (globalState) => globalState.role)

export const makeSelectHeadquartersTimezone = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.headquartersTimezone
  )

export const makeSelectSdkKeys = () =>
  createSelector(selectGlobal, (globalState) => globalState.sdkKeys)

export const makeSelectEnv = () =>
  createSelector(selectGlobal, (globalState) => globalState.env)
