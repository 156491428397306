import produce from 'immer'

import { UPDATE_COUNT, UPDATE_COUNTS } from './constants'
import {
  type NavigationNotificationCountActions,
  type NotificationCountState,
} from './types'

export const initialState: NotificationCountState = {}

export const reducer = (
  state = initialState,
  action: NavigationNotificationCountActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_COUNT:
        draft[action.key] = action.count
        break
      case UPDATE_COUNTS:
        draft = {
          ...draft,
          ...action.counts,
        }
        return draft
    }
  })
