import {
  createTheme,
  type Theme,
  type ThemeOptions,
} from '@material-ui/core/styles'
import { type TypographyOptions } from '@material-ui/core/styles/createTypography'
import { rem } from 'polished'

import * as box from './box'
import * as colors from './colors'
import * as fontFamily from './fontFamily'
import * as fontSizes from './fontSizes'
import * as fontWeights from './fontWeights'
import * as modal from './modal'

const BASE_FONT_SIZE = 16

export const extendedTheme = {
  name: 'Procurify theme',
  colors,
  fontSizes,
  fontWeights,
  fontFamily,
  box,
  modal,
  space: {
    xsmall: rem(4, BASE_FONT_SIZE), // 0.25
    small: rem(8, BASE_FONT_SIZE), // 0.5
    medium: rem(16, BASE_FONT_SIZE), // 1
    large: rem(24, BASE_FONT_SIZE), // 1.5
    xlarge: rem(32, BASE_FONT_SIZE), // 2
  },
  borders: {
    border: colors.content.border,
    borderTop: colors.content.border,
    borderRight: colors.content.border,
    borderBottom: colors.content.border,
    borderLeft: colors.content.border,
  },
  zIndex: {
    navigation: 100,
    appHeader: 1100,
    drawer: 1200,
    modal: 1300,
    tooltip: 1500,
    notification: 1600,
  },
  appHeader: {
    height: rem(72, BASE_FONT_SIZE),
  },
  navigation: {
    collapsedWidth: 88,
    fullWidth: 200,
    linkHeight: 36,
    sublinkHeight: 36,
  },
  content: {
    widthCentered: 1150,
  },
}

export const getThemeTypographyOptions = (
  htmlFontSize: number = BASE_FONT_SIZE
): TypographyOptions => {
  return {
    fontFamily: [fontFamily.body, fontFamily.header].join(','),
    fontWeightLight: fontWeights.normal,
    fontWeightMedium: fontWeights.bold,
    fontWeightRegular: fontWeights.normal,
    h1: {
      fontSize: rem(32, htmlFontSize),
      fontFamily: fontFamily.header,
      fontWeight: fontWeights.semibold,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: rem(28, htmlFontSize),
      fontFamily: fontFamily.header,
      fontWeight: fontWeights.semibold,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: rem(24, htmlFontSize),
      fontFamily: fontFamily.header,
      fontWeight: fontWeights.semibold,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: rem(20, htmlFontSize),
      fontFamily: fontFamily.header,
      fontWeight: fontWeights.semibold,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: rem(18, htmlFontSize),
      fontFamily: fontFamily.header,
      fontWeight: fontWeights.semibold,
      lineHeight: 1.2,
    },
    // Body
    body2: {
      fontSize: rem(14, htmlFontSize),
      fontWeight: fontWeights.normal,
      lineHeight: 1.5,
    },
    // Label
    subtitle1: {
      fontSize: rem(12, htmlFontSize),
      fontWeight: fontWeights.bold,
      lineHeight: 1.5,
    },
    // Caption
    subtitle2: {
      fontSize: rem(10, htmlFontSize),
      fontWeight: fontWeights.bold,
      lineHeight: 1.5,
    },
    button: {
      fontWeight: fontWeights.semibold,
    },
    htmlFontSize,
  }
}

export const muiThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 1160,
      sm: 1160,
      md: 1160,
      lg: 1160,
      xl: 1160,
    },
  },
  palette: {
    background: {
      default: colors.content.contentBackground,
      paper: colors.content.mainBackground,
    },
    divider: colors.content.border,
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.textPrimary,
      disabled: colors.textDisabled,
      hint: colors.textDisabled,
    },
    type: 'light',
  },
  // Our default space between elements is 16px
  spacing: 16,
  typography: getThemeTypographyOptions(BASE_FONT_SIZE),
  shadows: [
    'none',
    '0 2px 5px 0 rgba(0,0,0,0.20)',
    '0 2px 3px 0 rgba(0,0,0,0.15)',
    '0 2px 6px 0 rgba(0,0,0,0.05)',
    '0px 4px 4px 0px rgba(203,203,203,0.4)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  shape: {
    borderRadius: 4,
  },
}

export type ThemeInterface = Theme &
  typeof extendedTheme & {
    typography: {
      htmlFontSize?: number
    }
  }

export const theme = createTheme(
  muiThemeOptions,
  extendedTheme
) as ThemeInterface
