import * as React from 'react'

import {
  CardContentStyled,
  type ICardContentStyled,
} from './styles/CardContentStyled'

export interface ICardContent extends ICardContentStyled {}

export const CardContent = ({
  children,
  ...props
}: React.PropsWithChildren<ICardContent>) => (
  <CardContentStyled {...props}>{children}</CardContentStyled>
)

CardContent.displayName = 'CardContent'
