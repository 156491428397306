import styled from 'styled-components'

import { type NotificationType } from '../types'

const getColor = (props) => {
  switch (props.type) {
    case 'success':
      return props.theme.colors.success
    case 'error':
      return props.theme.colors.danger
    case 'info':
      return props.theme.colors.info
    case 'warning':
      return props.theme.colors.caution
  }
}

export interface INotificationIconsProps {
  type: NotificationType
}

export const NotificationIconStyled = styled.div<INotificationIconsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.theme.space.small};
  margin-top: ${(props) => props.theme.typography.pxToRem(3)};
  color: ${(props) => getColor(props)};
`
