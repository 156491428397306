import { rgba } from 'polished'
import { css } from 'styled-components'

export const boxShadow = (
  h = 0,
  v = '0.166em',
  blur = '0.333em',
  spread = 0,
  color = rgba(0, 0, 0, 0.2)
) => {
  return css`
    box-shadow: ${h} ${v} ${blur} ${spread} ${color};
  `
}
