import axios, { type AxiosRequestConfig } from 'axios'
import { isEmpty } from 'lodash'

import { redirectOnSessionExpired } from './redirectOnSessionExpired'
import {
  type IApiPagination,
  type IApiV1Pagination,
  type IApiV1Response,
  type IApiV3Error,
  type IApiV3Response,
} from '../../services/api/'
export const v1 = axios.create()

export const V1RequestInterceptor = (request: AxiosRequestConfig) => {
  if (request.method === 'get') {
    const { params = {} } = request
    params.page = params.current_page
    params.current_page = null
    params.limit = params.page_size
    params.page_size = null
    params.offset = (params.page - 1) * params.page_size
  }
  return request
}

v1.interceptors.request.use(V1RequestInterceptor)

/**
 *
 * @name extractV1Pagination
 * @description Formats V1 pagination into V3 pagination structure
 * @param {IApiV1Pagination} paging
 * @returns {IApiPagination} V3 Pagination
 */
export const extractV1Pagination = (
  paging: IApiV1Pagination
): IApiPagination => {
  const { count, page, next, prev: previous, pages, limit } = paging
  return {
    count,
    current_page: page,
    next,
    previous,
    num_pages: pages.length,
    page_size: limit,
  }
}

/**
 *
 * @name transformIntoV3Error
 * @description Formats V1 error response into V3 error
 * @param {IApiV1Response} v1Response
 * @returns {IApiV3Error}
 */
export const transformIntoV3Error = (
  v1Response: IApiV1Response
): IApiV3Error => {
  const { code, description, ...otherV1Response } = v1Response
  return {
    data: { ...otherV1Response },
    errors: {
      v1error: {
        code: code.toString(),
        message: description,
      },
    },
  } as IApiV3Error
}

export const V1ResponseInterceptor = (response) => {
  const { data }: { data: IApiV1Response } = response
  const { data: innerData, paging, metadata = {}, success } = data
  // V1 Api returns 200 with 'success = false', so we need to reject it manually
  if (!success) {
    response.data = transformIntoV3Error(data)
    throw response
  }

  const formattedData = {
    data: innerData,
    metadata: {
      ...metadata,
      ...(!isEmpty(paging) && { pagination: extractV1Pagination(paging) }),
    },
  } as IApiV3Response

  Object.assign(response, { data: formattedData })
  return response.data
}

export const V1ResponseErrorInterceptor = (error) => {
  throw error.response
}
v1.interceptors.response.use(V1ResponseInterceptor, V1ResponseErrorInterceptor)
v1.interceptors.response.use(null, redirectOnSessionExpired)

v1.defaults.xsrfHeaderName = 'X-CSRFToken'
v1.defaults.xsrfCookieName = 'csrftoken'
