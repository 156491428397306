import * as React from 'react'

export interface ModalContext {
  component?: React.ComponentType<any>
  componentProps?: any
  hideModal?: () => void
  dismissModal?: () => void
  showModal?: <Props = any>(
    component: React.ComponentType | ((props: Props) => JSX.Element),
    componentProps: Props
  ) => Promise<any>
}

export const ModalContext = React.createContext<ModalContext>({})

export const ModalContextProvider = ModalContext.Provider
export const ModalContextConsumer = ModalContext.Consumer
