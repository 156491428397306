import { defineMessages } from 'react-intl'

const scope = `procurify.hooks.useGetBudgetBreakdown`

export default defineMessages({
  currentSpendBarLabel: {
    id: `${scope}.currentSpendBarLabel`,
    defaultMessage: 'Current spend bar',
  },
  currentRequestBarLabel: {
    id: `${scope}.currentRequestBarLabel`,
    defaultMessage: 'Current request bar',
  },
  otherPendingRequestsBarLabel: {
    id: `${scope}.otherPendingRequestsBarLabel`,
    defaultMessage: 'Other pending requests bar',
  },
  approvedSpendBarLabel: {
    id: `${scope}.approvedSpendBarLabel`,
    defaultMessage: 'Approved spend bar',
  },
  purchasedSpendBarLabel: {
    id: `${scope}.purchasedSpendBarLabel`,
    defaultMessage: 'Purchased spend bar',
  },
  billedSpendBarLabel: {
    id: `${scope}.billedSpendBarLabel`,
    defaultMessage: 'Billed spend bar',
  },
})
