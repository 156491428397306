import { defineMessages } from 'react-intl'

const scope = `procurify.hooks.useCustomFieldsValidationSchema`

export default defineMessages({
  fieldIsRequiredMessage: {
    id: `${scope}.fieldIsRequiredMessage`,
    defaultMessage: '{name} is required',
  },
  thisField: {
    id: `${scope}.thisField`,
    defaultMessage: 'This field',
  },
})
