import { type Enum } from '@webapp/utils'

export const PayCardStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  CANCELED: 'canceled',
} as const
export type PayCardStatus = Enum<typeof PayCardStatuses>

export const PayCardStatusesV2 = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending',
  CANCELED: 'canceled',
  REQUESTED: 'requested',
} as const
export type PayCardStatusV2 = Enum<typeof PayCardStatusesV2>
