import { display, flexbox, sizing, spacing } from '@material-ui/system'
import styled, { css } from 'styled-components'

import { type IFlexRowProps } from '../FlexRow'

export const FlexRowStyled = styled.div<IFlexRowProps>`
  display: flex;
  ${(props) => {
    if (props.spacing === 0) return {}
    const gutter = props.spacing * props.theme.spacing()
    return css`
      margin: ${props.theme.typography.pxToRem(-gutter / 2)};
      width: calc(100% + ${gutter}px);
      padding: 0;
    `
  }}
  ${display}
  ${flexbox}
  ${sizing}
  ${spacing}
`
FlexRowStyled.displayName = 'FlexRowStyled'
