import { type Enum } from '@webapp/utils'

export const PurchaseOrderItemRecurrenceTypes = {
  YEAR: 0,
  MONTH: 1,
} as const

export type PurchaseOrderItemRecurrenceType = Enum<
  typeof PurchaseOrderItemRecurrenceTypes
>
