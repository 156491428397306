import * as React from 'react'

import { type INotificationProps, Notification } from '@procurify/ui'
import { toast as toastify, type ToastOptions } from 'react-toastify'

export const error = (
  notificationProps: Omit<INotificationProps, 'type'>,
  toastOptions?: ToastOptions
) =>
  toastify.error(
    <Notification type='error' {...notificationProps} />,
    toastOptions
  )

export const warning = (
  notificationProps: Omit<INotificationProps, 'type'>,
  toastOptions?: ToastOptions
) =>
  toastify.warn(
    <Notification type='warning' {...notificationProps} />,
    toastOptions
  )

export const info = (
  notificationProps: Omit<INotificationProps, 'type'>,
  toastOptions?: ToastOptions
) =>
  toastify.info(
    <Notification type='info' {...notificationProps} />,
    toastOptions
  )

export const success = (
  notificationProps: Omit<INotificationProps, 'type'>,
  toastOptions?: ToastOptions
) =>
  toastify.success(
    <Notification type='success' {...notificationProps} />,
    toastOptions
  )

export const toast = {
  error,
  info,
  success,
  warning,
}
