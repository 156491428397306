import * as React from 'react'

import { ModalRoot, ModalProvider } from '@procurify/ui'
import { theme } from '@procurify/ui-theme/src/angular'
import { queryClient } from '@procurify/webapp/src/api/queryClient'
import { LanguageProvider } from '@procurify/webapp/src/hoc/LanguageProvider'
import { ThemeWrapper } from '@procurify/webapp/src/hoc/ThemeWrapper'
import { UIProvider } from '@procurify/webapp/src/hoc/UIProvider'
import { store } from '@procurify/webapp/src/store'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

export const withBridgeProviders = <T extends {} = any>(
  Component: React.ComponentType<T>
) => {
  return function WithBridgeProviders(props: T) {
    return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <LanguageProvider>
            <ThemeWrapper theme={theme}>
              <UIProvider>
                <ModalProvider>
                  <Component {...props} />
                  <ModalRoot />
                </ModalProvider>
              </UIProvider>
            </ThemeWrapper>
          </LanguageProvider>
        </QueryClientProvider>
      </Provider>
    )
  }
}

export const withBridgeProvidersAndRouter = <T extends {} = any>(
  Component: React.ComponentType<T>
) => {
  const ComponentWithRouter = (props: T) => (
    <BrowserRouter forceRefresh>
      <Component {...props} />
    </BrowserRouter>
  )

  return function WithBridgeProviders(props: T) {
    return withBridgeProviders(ComponentWithRouter)(props)
  }
}
