import { type Enum } from '@webapp/utils'

export const StripeFinancialAccountStatuses = {
  UNOPENED: 0,
  OPEN: 1,
  CLOSED: 2,
} as const
export type StripeFinancialAccountStatus = Enum<
  typeof StripeFinancialAccountStatuses
>
