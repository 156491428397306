import * as React from 'react'

import { FlexRowStyled } from './styles/FlexRowStyled'
import { type SizingProps, type SpacingProps } from '@ui/core/types'

export interface IFlexRowProps extends SizingProps, SpacingProps {
  children?: React.ReactNode
  /**
   * If `none`, the component is removed from the layout, including the
   * accessibility tree.
   */
  display?: 'flex' | 'none'
  /**
   * Sets the behaviour of the component overflow contents in both horizontal
   * and vertical directions.
   */
  overflow?: 'clip' | 'hidden' | 'scroll' | 'auto' | 'visible'
  /**
   * Sets the gutter space between each children flex items while keeping these
   * aligned with the four sides of the component.
   */
  spacing?: number
  /**
   * Sets how children flex items are placed along the component main axis.
   */
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse'
  /**
   * Sets whether children flex items are forced onto one line or can wrap onto
   * multiple lines.
   */
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  /**
   * Sets the distribution of space between and around children flex items along
   * the component cross-axis.
   */
  alignContent?:
    | 'baseline'
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'normal'
    | 'space-around'
    | 'space-between'
    | 'stretch'
  /**
   * Sets the `alignSelf` value on all children flex items as a group along the
   * component cross-axis.
   */
  alignItems?:
    | 'baseline'
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'normal'
    | 'stretch'
  /**
   * Sets the distribution of space between and around children flex items
   * along the component main-axis. Using `justifyContent` overrides any value
   * set via the `spacing` prop.
   */
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'normal'
    | 'space-around'
    | 'space-between'
}

export const FlexRow = ({
  children,
  alignContent = 'normal',
  alignItems = 'normal',
  display = 'flex',
  flexDirection = 'row',
  flexWrap = 'wrap',
  justifyContent = 'normal',
  overflow = 'visible',
  spacing = 1,
  ...props
}: IFlexRowProps) => (
  <FlexRowStyled
    alignContent={alignContent}
    alignItems={alignItems}
    display={display}
    flexDirection={flexDirection}
    flexWrap={flexWrap}
    justifyContent={justifyContent}
    overflow={overflow}
    spacing={spacing}
    {...props}
  >
    {React.Children.map(
      children,
      (child) =>
        child &&
        React.cloneElement(child as React.ReactElement<any>, {
          spacing,
        })
    )}
  </FlexRowStyled>
)

FlexRow.displayName = 'FlexRow'
