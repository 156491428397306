import { type Enum } from '@webapp/utils'

export const APCreditCardTypes = {
  OTHER: 0,
  VISA: 1,
  MASTERCARD: 2,
  AMERICAN_EXPRESS: 3,
  DISCOVER: 4,
  JCB: 5,
  DINERS_CLUB: 6,
} as const

export type APCreditCardType = Enum<typeof APCreditCardTypes>
