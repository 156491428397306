import * as React from 'react'

import { type SpacingProps, type TypographyProps } from '@material-ui/system'
import classNames from 'classnames'
import { type DefaultTheme } from 'styled-components'

import './fonts/procurify/icons.scss'
import { type IconTypes } from './iconTypes'
import { IconStyled } from './styles/IconStyled'

export interface IIconProps extends TypographyProps, SpacingProps {
  ariaLabel?: string
  /**
   * Name or Hex of color
   * @example
   * darkGreen, souflare, #333
   */
  color?: keyof DefaultTheme['colors']
  /**
   * Name of the icon
   * @see IconTypes
   */
  icon: IconTypes
  /** Size of the icon in Number (12), or String ('12px') */
  size?: number | string
  className?: any
  style?: React.CSSProperties
  bold?: boolean
}

export class Icon extends React.Component<IIconProps> {
  static defaultProps = {
    color: '#FFF',
  }

  public static Styled: typeof IconStyled = IconStyled

  render() {
    const { icon, className, ...otherProps } = this.props
    if (!icon) {
      return null
    }

    return (
      <IconStyled
        className={classNames(`icon-${this.props.icon}`, className)}
        color={this.props.color}
        size={this.props.size}
        aria-label={this.props.ariaLabel}
        role='img'
        {...otherProps}
      />
    )
  }
}
