import { Enum } from '../../utils/enums'

export const PaymentStatuses = {
  DRAFT: 0,
  CONFIRMED: 1, // FKA "Pending"
  APPROVED: 2,
  DENIED: 3,
  PAID: 5,
  PROCESSING: 7,
  FAILED: 8,
  SCHEDULED: 9,
} as const
export type PaymentStatus = Enum<typeof PaymentStatuses>

/**
 * @deprecated - to be removed with FT_4280_BILL_PAYMENT_CONSOLIDATION
 */
export const LegacyPaymentStatusEnumValues = [
  'Created',
  'Edited',
  'Deleted',
  'Confirmed',
  'Approved',
  'Denied',
  'Void',
  'Partially Paid',
  'Fully Paid',
]
/**
 * @deprecated - to be removed with FT_4280_BILL_PAYMENT_CONSOLIDATION
 */
export const LegacyPaymentStatus = Enum(
  'Created',
  'Edited',
  'Deleted',
  'Confirmed',
  'Approved',
  'Denied',
  'Void',
  'Partially Paid',
  'Fully Paid'
)

/**
 * @deprecated - to be removed with FT_4280_BILL_PAYMENT_CONSOLIDATION
 */
export const PaymentFromTypes = {
  USD_FINANCIAL_ACCOUNT: 'USD_FINANCIAL_ACCOUNT',
  CAD_FINANCIAL_ACCOUNT: 'CAD_FINANCIAL_ACCOUNT',
  OTHER: 'OTHER',
} as const
/**
 * @deprecated - to be removed with FT_4280_BILL_PAYMENT_CONSOLIDATION
 */
export type PaymentFromType = Enum<typeof PaymentFromTypes>
