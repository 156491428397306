import { CardHeaderStyled } from '@procurify/ui'
import { DividerStyled } from '@ui/elements/Divider/styles/DividerStyled'
import { em } from 'polished'
import styled from 'styled-components'

import background from '../assets/authentication-banner-bg.png'

interface IIntermediateLoginStyledProps {}

export const IntermediateLoginStyled = styled.div<IIntermediateLoginStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: ${(props) => props.theme.typography.pxToRem(160)};

  @media only screen and (max-device-width: ${(props) => {
      return em(props.theme.breakpoints.values.sm)
    }}) {
    padding-top: ${(props) =>
      props.theme.typography.pxToRem(props.theme.spacing() * 10)};
    padding-left: ${(props) =>
      props.theme.typography.pxToRem(props.theme.spacing() * 1)};
    padding-bottom: ${(props) =>
      props.theme.typography.pxToRem(props.theme.spacing() * 1)};
    padding-right: ${(props) =>
      props.theme.typography.pxToRem(props.theme.spacing() * 1)};
  }

  ${CardHeaderStyled} {
    background-image: url(${background});
    padding: ${(props) => props.theme.typography.pxToRem(60)}
      ${(props) => props.theme.typography.pxToRem(24)};
    background-size: cover;
    background-position: center;
  }

  .image-logo {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: ${(props) => props.theme.typography.pxToRem(160)};
  }

  ${DividerStyled} {
    border-left: 0;
    border-bottom: 0;
    border-right: 0;
  }
`
