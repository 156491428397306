import { useProcurifyIntl } from '@webapp/hooks/useProcurifyIntl'
import { type SimpleVendor } from '@webapp/models'
import messages from './messages'

export const formatVendor = (
  vendor: SimpleVendor,
  otherVendor: string,
  otherVendorPrefix: string
): string => {
  if (!vendor) {
    return
  } else if (vendor.id === 1) {
    return otherVendorPrefix + otherVendor
  } else {
    return vendor.name
  }
}

export function useFormatVendor() {
  const intl = useProcurifyIntl()

  const otherVendorPrefix = intl.formatMessage(messages.otherPrefix)

  return (vendor: SimpleVendor, otherVendor: string): string => {
    return formatVendor(vendor, otherVendor, otherVendorPrefix)
  }
}
