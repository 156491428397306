import * as React from 'react'

import { type ThemeInterface } from '@procurify/ui-theme'
import { ThemeProvider } from 'styled-components'

export class ThemeWrapper extends React.PureComponent<{
  theme: ThemeInterface
}> {
  render() {
    return (
      <ThemeProvider theme={this.props.theme}>
        {this.props.children as any}
      </ThemeProvider>
    )
  }
}
