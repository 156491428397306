import React from 'react'

import * as Sentry from '@sentry/react'
import { type AxiosError } from 'axios'
import * as HttpStatus from 'http-status-codes'
import produce from 'immer'
import isEmpty from 'lodash/isEmpty'

import { type GlobalAPIResponse } from '@webapp/api/GlobalAPI'
import {
  type AuthenticationErrorType,
  AuthenticationErrorTypes,
} from '@webapp/models/enums/AuthenticationErrorType'
import { type IApiV3Error } from '@webapp/services/api'
import { getGlobal, getGlobalPublic } from '../../api'
import { logoutUrl, redirectUri } from '../../configs'

interface AuthConfigContextValues {
  authorize: () => void
  authorizationUrl: string
  error: boolean
  errorCode?: AuthenticationErrorType
  hasSSOConnection: boolean
  isAuthenticated: boolean
  isLoading: boolean
  logout: () => void
  logoutUrl: string
  redirectUri: string
  ssoAuthorizationUrl: string
  ssoConnectionName: string
  userGlobalData: GlobalAPIResponse
}

export const AUTH_CONFIG_CONTEXT_DEFAULTS: AuthConfigContextValues = {
  authorize: () => undefined,
  authorizationUrl: null,
  error: false,
  errorCode: null,
  hasSSOConnection: false,
  isAuthenticated: false,
  isLoading: false,
  logout: () => undefined,
  logoutUrl,
  redirectUri,
  ssoAuthorizationUrl: null,
  ssoConnectionName: null,
  userGlobalData: null,
}

export const AuthConfigContext = React.createContext<AuthConfigContextValues>(
  AUTH_CONFIG_CONTEXT_DEFAULTS
)

export interface AuthenticationProviderState {
  authorizationUrl: string
  error: boolean
  errorCode?: AuthenticationErrorType
  globalData: GlobalAPIResponse
  hasSSOConnection: boolean
  isAuthenticated: boolean
  isLoading: boolean
  ssoAuthorizationUrl: string
  ssoConnectionName: string
}

export const AuthenticationProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [authState, setAuthState] = React.useState<AuthenticationProviderState>(
    {
      authorizationUrl: null,
      error: false,
      globalData: null,
      hasSSOConnection: false,
      isAuthenticated: false,
      isLoading: true,
      ssoAuthorizationUrl: null,
      ssoConnectionName: null,
      errorCode: null,
    }
  )

  React.useEffect(() => {
    /**
     * Send the very first request to check if we are authenticated.
     * Any error response will be treated as NOT authenticated.
     *
     * This request has to use raw axios so it does not redirect users to logout
     * when the response errors with 401
     */
    getGlobal()
      .then((res) => {
        return res.data?.data
      })
      .then((globalData) => {
        if (!globalData) throw new Error()
        /**
         * We store the user's global data in the context to be reused by:
         *  - React's ApplicationContextProvider
         *  - Angular's BridgeContextProvider
         *  - Angular's window.Global
         */
        setAuthState((state) =>
          produce(state, (draft) => {
            draft.globalData = globalData
            draft.isAuthenticated = true
            draft.isLoading = false
            draft.error = false
          })
        )
      })
      .catch((globalError: AxiosError<IApiV3Error>) => {
        if (
          globalError?.response?.data?.errors?.[
            AuthenticationErrorTypes.STAFF_ACCESS_EXPIRED
          ]?.code === AuthenticationErrorTypes.STAFF_ACCESS_EXPIRED
        ) {
          setAuthState((state) =>
            produce(state, (draft) => {
              draft.globalData = null
              draft.isAuthenticated = false
              draft.isLoading = false
              draft.error = true
              draft.errorCode = AuthenticationErrorTypes.STAFF_ACCESS_EXPIRED
            })
          )
          return
        }

        /**
         * If the error is not 401, we simply set the error state to true.
         * ie. 404 Not found when the domain does not exist.
         */
        if (globalError?.response?.status !== HttpStatus.UNAUTHORIZED) {
          setAuthState((state) =>
            produce(state, (draft) => {
              draft.globalData = null
              draft.isAuthenticated = false
              draft.isLoading = false
              draft.error = true
            })
          )

          return
        }

        /**
         * If /api/v3/global/ returns a 401, we call /api/v3/global/public
         * to determine if we have a SSO connection or not.
         */
        getGlobalPublic()
          .then((globalPublicResponse) => {
            const authentication =
              globalPublicResponse.data?.data?.authentication

            setAuthState((state) =>
              produce(state, (draft) => {
                draft.isAuthenticated = false

                draft.isLoading = false
                draft.error = false

                draft.authorizationUrl = authentication?.authorization_url

                draft.hasSSOConnection =
                  !isEmpty(authentication) &&
                  authentication.connection?.name.length > 0
                draft.ssoConnectionName = authentication?.connection?.name
                draft.ssoAuthorizationUrl =
                  authentication?.sso_authorization_url
              })
            )
          })
          .catch((globalPublicError) => {
            setAuthState((state) =>
              produce(state, (draft) => {
                draft.isAuthenticated = false
                draft.isLoading = false
                draft.error = true
              })
            )

            Sentry.withScope((scope) => {
              scope.setLevel(Sentry.Severity.Fatal)
              scope.setTags({
                errorBoundary: 'AuthenticationProvider',
              })
              scope.setFingerprint([globalPublicError?.message])
              Sentry.captureException(globalPublicError)
            })
          })
      })
  }, [])

  return (
    <AuthConfigContext.Provider
      value={{
        authorize: () => {
          if (!authState.authorizationUrl) return

          window.location.replace(authState.authorizationUrl)
        },
        authorizationUrl: authState.authorizationUrl,
        error: authState.error,
        errorCode: authState.errorCode,
        hasSSOConnection: authState.hasSSOConnection,
        isAuthenticated: authState.isAuthenticated,
        isLoading: authState.isLoading,
        logout: () => {
          window.location.replace(logoutUrl)
        },
        logoutUrl,
        redirectUri,
        ssoAuthorizationUrl: authState.ssoAuthorizationUrl,
        ssoConnectionName: authState.ssoConnectionName,
        userGlobalData: authState.globalData,
      }}
    >
      {children}
    </AuthConfigContext.Provider>
  )
}
