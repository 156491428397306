import { type SpendingCardApproveListViewTab } from '@webapp/containers/SpendingCardApproveListView/types'
import {
  UPDATE_COUNTS,
  UPDATE_LAST_TAB,
  UPDATE_SHOW_BUDGET_PENDING_AMOUNTS,
} from './constants'
import {
  type IPayApprovalsTabCount,
  type UpdateCountsAction,
  type UpdateLastTabAction,
  type UpdateShowBudgetPendingAmountsAction,
} from './types'

export const updateCounts = (
  counts: IPayApprovalsTabCount
): UpdateCountsAction => ({
  type: UPDATE_COUNTS,
  counts,
})

export const updateLastTab = (
  tab: SpendingCardApproveListViewTab
): UpdateLastTabAction => ({
  type: UPDATE_LAST_TAB,
  tab,
})

export const updateShowBudgetPendingAmounts = (
  show: boolean
): UpdateShowBudgetPendingAmountsAction => ({
  type: UPDATE_SHOW_BUDGET_PENDING_AMOUNTS,
  show,
})
