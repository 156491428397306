import { Enum } from '../../utils/enums'

export const OrderItemStatusEnumValues = [
  'Created',
  'Edited',
  'Deleted',
  'Approved',
  'Denied',
  'Purchased',
  'Partially Received',
  'Fully Received',
]
export const OrderItemStatusDeprecated = Enum(
  'Created',
  'Edited',
  'Deleted',
  'Approved',
  'Denied',
  'Purchased',
  'Partially Received',
  'Fully Received'
)

export const OrderItemStatuses = {
  PENDING: null,
  PENDING_PURCHASE: 1,
  APPROVED: 0,
  PURCHASED: 2,
  RECEIVED: 3,
  REJECTED: 4,
  PARTIALLY_RECEIVED: 5,
  DENIED: 7,
  DRAFT: 8,
} as const

export const OrderItemTypes = {
  ONE_TIME: 0,
  RECURRING: 1,
  BLANKET: 2,
} as const

export const OrderItemReceiveMethods = {
  QUANTITY: 0,
  CURRENCY_AMOUNT: 1,
} as const

export type OrderItemStatus = Enum<typeof OrderItemStatuses>
export type OrderItemType = Enum<typeof OrderItemTypes>
export type OrderItemReceiveMethod = Enum<typeof OrderItemReceiveMethods>
