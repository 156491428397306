import { type Enum } from '@webapp/utils'

export const ApprovalHistoryActions = {
  REQUEST: 0,
  FINAL_APPROVE: 1,
  DENY: 2,
  APPROVE_AND_PASS_ON: 3,
  PURCHASE: 4,
  REJECT_LINE_ITEMS: 5,
  UPDATE_ON_RECEIVE: 6,
  MARK_ORDER_RECEIVED: 7,
  CLOSE_PO: 8,
  UNMARK_ORDER_RECEIVED: 9,
  OPEN_PO: 10,
  REQUEST_LINE_ITEM: 11,
  UNDO_RECEIVE_LOG: 12,
  CANCEL_PO: 13,
  STOCK_LINE_ITEM: 14,
  TRANSACTION_APPROVED: 15,
  TRANSACTION_DENIED: 16,
  TRANSACTION_PARTIALLY_APPROVED: 17,
  TRANSACTION_ALLOCATION: 18,
  ORDER_PURCHASED: 19,
  RECORD_PO: 20,
  RESUBMIT: 21,
} as const

export type ApprovalHistoryAction = Enum<typeof ApprovalHistoryActions>
