export enum Permissions {
  ADD_ORDER = 'add_order',
  ADD_EXPENSE = 'add_expensereport',
  ADD_TRAVEL = 'add_travel',
  ADD_REQUEST = 'add_request',
  APPROVE_ORDER = 'approve_order',
  APPROVE_EXPENSEREPORT = 'approve_expensereport',
  APPROVE_TRAVEL = 'approve_travel',
  APPROVE_BILL = 'approve_bill',
  APPROVE_PAYMENT = 'approve_payment',
  APPROVE_PAY_REQUEST = 'approve_pay_request',
  APPROVE_VENDOR_REQUEST = 'approve_vendor_request',
  PROCURE_ADD_PO = 'add_po',
  PROCURE_CATALOG = 'add_catalogitems',
  PROCURE_VENDORS = 'add_vendor',
  PROCURE_CONTRACTS = 'manage_contract',
  RECEIVE_PO = 'receive_po',
  SETTINGS_MANAGE_CHART_OF_ACCOUNTS = 'add_accountcode',
  SETTINGS_MANAGE_BUDGET_CORRECTION = 'manage_budget_correction',
  SETTINGS_MANAGE_BUDGETS = 'add_budget',
  SETTINGS_MANAGE_LOCATIONS = 'add_companyaccount',
  SETTINGS_MANAGE_USERS = 'add_user',
  SETTINGS_MANAGE_DATE_FORMAT = 'manage_date_format',
  SETTINGS_EXPORT_DATA = 'export_data',
  SETTINGS_IMPORT_DATA = 'import_data',
  SETTINGS_APPROVAL_ROUTING = 'add_approverlist',
  SETTINGS_APPROVER_WATCHLIST = 'approver_watchlist',
  SETTINGS_PROCUREMENT_ACCESS = 'procurement_access',
  SETTINGS_REPORTS_ACCESS = 'report_access',
  SETTINGS_GRANT_SUPPORT_ACCESS_CONSENT = 'grant_support_access_consent',
  SETTINGS_MANAGE_ROLES = 'add_permission',
  SETTINGS_MANAGE_CURRENCY = 'add_currency',
  SETTINGS_MANAGE_BUDGET_SETTINGS = 'allow_overbudget',
  SETTINGS_MANAGE_TAXES = 'add_tax',
  SETTINGS_MANAGE_CREDITCARDS = 'add_creditcard',
  SETTINGS_CUSTOMIZE_BILL_COSTS = 'customize_bill_costs',
  SETTINGS_ADD_MONTH_CLOSE = 'add_monthclose',
  SETTINGS_ADD_UNITS = 'add_unit',
  SETTINGS_CUSTOMIZE_RFO_FIELDS = 'customize_rfo_fields',
  SETTINGS_CUSTOMIZE_PO_FIELDS = 'customize_po_fields',
  SETTINGS_MANAGE_CATALOG_LOCK = 'manage_catalog_permission',
  SETTINGS_CUSTOMIZE_EXPENSE_FORM = 'manage_expense',
  SETTINGS_MANAGE_VENDOR_PERFORMANCE = 'manage_vendor_performance',
  SETTINGS_MANAGE_INTEGRATION = 'add_integration',
  SETTINGS_MANAGE_CONTRACTS = 'manage_contract_settings',
  REPORTS_VIEW_ACCRUAL = 'view_reports_accrual',
  REPORTS_VIEW_EXPENSE_RECORDS = 'view_reports_expense_records',
  REPORTS_VIEW_RECEIVING_SUMMARY = 'view_reports_receiving_summary',
  REPORTS_VIEW_SPEND_TRACKER = 'view_reports_spend_tracker',
  REPORTS_VIEW_ORDERS_BY_VENDOR = 'view_reports_orders_by_vendor',
  REPORTS_VIEW_ORDERS_BY_USER = 'view_reports_orders_by_user',
  REPORTS_VIEW_ORDERS_BY_DEPARTMENT = 'view_reports_orders_by_department',
  REPORTS_VIEW_EXPENSES_BY_DEPARTMENT = 'view_reports_expenses_by_department',
  ACCOUNTS_PAYABLE_ADD_BILL = 'add_bill',
  ACCOUNTS_PAYABLE_RECONCILIATION = 'add_statement',
  ACCOUNTS_PAYABLE_PAYMENT_PROCESSING = 'add_payment',
  ACCOUNTS_PAYABLE_CREATE_PAYMENTS = 'add_payment',
  ACCOUNTS_PAYABLE_MANAGE_PAYMENTS = 'remit_payment',
  ACCOUNTS_PAYABLE_VIEW_BILL = 'view_bill',
  ACCOUNTS_PAYABLE_EDIT_DRAFT_PENDING_DENIED_BILLS = 'edit_draft_pending_and_denied_bills',
  ACCOUNTS_PAYABLE_EDIT_APPROVED_BILLS = 'edit_approved_bills',
  ACCOUNTS_PAYABLE_DELETE_SUBMITTED_BILLS = 'delete_submitted_bills',
  LEGACY_PAYMENT = 'view_legacy_payment',
  REPORTS_VIEW_MOBILE_REPORTS = 'view_reports_budgets',
  SETTINGS_EXPORT_DATA_BILL = 'export_bill',
  SETTINGS_EXPORT_DATA_UNBILLED_BILL_ITEM = 'export_unbilled_bill_item', // Unbilled PO Items
  SETTINGS_EXPORT_DATA_BUDGET = 'export_budget',
  SETTINGS_EXPORT_DATA_CATALOG = 'export_catalog',
  SETTINGS_EXPORT_DATA_LOCATION = 'export_location',
  SETTINGS_EXPORT_DATA_EXPENSE_REPORT = 'export_expense_report',
  SETTINGS_EXPORT_DATA_PURCHASED_ORDER_ITEM = 'export_purchased_order_item', // Purchase Order Items
  SETTINGS_EXPORT_DATA_REQUESTED_ORDER_ITEM = 'export_requested_order_item', // Order Items
  SETTINGS_EXPORT_DATA_PURCHASE_ORDER = 'export_purchase_order', // Receive History
  SETTINGS_EXPORT_DATA_TRAVEL = 'export_travel',
  SETTINGS_EXPORT_DATA_USER_PROFILE = 'export_user_profile',
  SETTINGS_EXPORT_DATA_TRANSACTION = 'export_pay_transaction',
  SPENDING_CARD_MANAGE_CARD = 'manage_pay_card',
  SETTINGS_MANAGE_FINANCIAL_ACCOUNT = 'add_financialaccount',
  DATA_AND_INSIGHTS_SPEND_INSIGHTS = 'view_spend_insights',
  DATA_AND_INSIGHTS_RETURN_ON_SPEND = 'view_return_on_spend',
}
