import * as React from 'react'

import { type IUploadProps } from '@ui/blocks/Upload'

interface IKeyValueStringMap {
  [key: string]: string
}

export interface IUIProviderValue {
  i18n?: {
    decimalSeparator: string
    thousandSeparator: string
    language: IKeyValueStringMap
    locale: string
  }
  NumberField?: {
    decimalPlaces: number
  }
  Uploader?: {
    headers: {
      [x: string]: string
    }
  }
  Upload?: Partial<IUploadProps>
  /*
   * DOM Node reference for portal components to use. Defaults to document.body; components inside shadow DOM use their own container obtained by ref in WithBridgeProviders HOC
   */
  container?: HTMLElement
}

export const defaultUIContextValues = {
  i18n: {
    decimalSeparator: '.',
    thousandSeparator: ',',
    language: {
      'scheduler.day': 'day',
      'scheduler.week': 'week',
    },
    locale: 'en',
  },
  NumberField: {
    decimalPlaces: 2,
  },
  Uploader: {
    headers: {},
  },
  Upload: {},
  container: document.body,
}

export const UIContext = React.createContext<IUIProviderValue>(
  defaultUIContextValues
)

export const UIConsumer = UIContext.Consumer
export const UIProvider = UIContext.Provider
