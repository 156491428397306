import { sizing, spacing } from '@material-ui/system'
import styled from 'styled-components'

import { CardContentStyled } from '../components/CardContent/styles/CardContentStyled'
import { CardMetaStyled } from '../components/CardMeta/styles/CardMetaStyled'
import { type SizingProps, type SpacingProps } from '@ui/core/types'

export interface ICardStyledProps
  extends SizingProps,
    Pick<SpacingProps, 'm' | 'mt' | 'mr' | 'mb' | 'ml' | 'mx' | 'my'> {}

export const CardStyled = styled.div<ICardStyledProps>`
  position: relative;
  background: ${(props) => props.theme.colors.content.contentBackground};
  border-radius: ${(props) =>
    props.theme.box.borderRadius(props.theme.typography.htmlFontSize)};

  box-shadow: ${(props) => props.theme.shadows[3]};

  ${spacing}
  ${sizing}

  ${CardContentStyled} + ${CardMetaStyled} {
    border-top: 1px solid ${(props) => props.theme.colors.content.border};
  }
`

CardStyled.displayName = 'CardStyled'
