import { logoutUrl } from '@procurify/authentication/src/configs'
import * as HttpStatus from 'http-status-codes'

import { APISessionExpiredError } from '@webapp/errors/api'
import { AuthenticationErrorTypes } from '@webapp/models/enums/AuthenticationErrorType'
import { type IApiError } from '@webapp/services/api'

export const InvalidSessionMessage = {
  MISSING: 'Authentication credentials were not provided',
} as const

export const redirectOnSessionExpired = (error: IApiError) => {
  if (
    error?.status === HttpStatus.UNAUTHORIZED &&
    error?.data?.errors?.detail?.message?.includes(
      InvalidSessionMessage.MISSING
    )
  ) {
    window.location.replace(logoutUrl)

    throw new APISessionExpiredError<IApiError>(
      InvalidSessionMessage.MISSING,
      error
    )
  }

  if (
    error?.status === HttpStatus.UNAUTHORIZED &&
    error?.data?.errors?.[AuthenticationErrorTypes.STAFF_ACCESS_EXPIRED]
      ?.code === AuthenticationErrorTypes.STAFF_ACCESS_EXPIRED
  ) {
    window.location.replace(logoutUrl)
    return
  }

  throw error
}
