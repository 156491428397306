import axios from 'axios'

import type { GlobalAPIResponse } from '@webapp/api/GlobalAPI'
import { type IApiResponse } from '@webapp/services/api'

export const ENDPOINT = '/api/v3/global/'
export const GLOBAL_API_URL = ENDPOINT
export const GLOBAL_PUBLIC_API_URL = '/api/v3/global-public/'

interface GetGlobalPublicSchema {
  data: {
    authentication?: {
      connection: {
        name: string
      }
      authorization_url: string
      sso_authorization_url: string
    }
  }
}

export const getGlobalPublic = () =>
  axios.get<GetGlobalPublicSchema>(GLOBAL_PUBLIC_API_URL, {
    headers: {
      Authorization: undefined,
    },
  })

export const getGlobal = (params = {}) => {
  return axios.get<IApiResponse<GlobalAPIResponse>>(GLOBAL_API_URL)
}
