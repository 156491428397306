import { type Enum } from '@webapp/utils'

export const BudgetIntervalTypes = {
  ENTIRE_DATE_RANGE: 'none',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
} as const

export type BudgetIntervalType = Enum<typeof BudgetIntervalTypes>
