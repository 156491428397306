import * as React from 'react'

import { BaseButtonStyled } from './styles/BaseButtonStyled'
import { ButtonLoaderStyled } from './styles/ButtonLoaderStyled'
import { Icon, type IconTypes } from '../Icon'
import { CircleLoader } from '../Loaders'

type NativeButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

export interface IBaseButtonProps extends NativeButtonProps {
  isLoading?: boolean
  icon?: IconTypes
  iconComponent?: React.ReactNode
  fullWidth?: boolean
  ref?: React.Ref<HTMLButtonElement>
}

export const BaseButton = React.forwardRef<HTMLButtonElement, IBaseButtonProps>(
  (props: React.PropsWithChildren<IBaseButtonProps>, ref) => {
    const {
      children,
      isLoading,
      icon,
      iconComponent,
      disabled,
      ...otherProps
    } = props

    const renderContent = () => {
      return (
        <>
          {iconComponent ? iconComponent : icon ? <Icon icon={icon} /> : null}
          {children}
        </>
      )
    }

    return (
      <BaseButtonStyled
        ref={ref}
        disabled={isLoading || disabled}
        {...otherProps}
      >
        {renderContent()}

        {isLoading && (
          <ButtonLoaderStyled>
            <CircleLoader color='textPrimary' size={14} />
          </ButtonLoaderStyled>
        )}
      </BaseButtonStyled>
    )
  }
)

BaseButton.displayName = 'BaseButton'
