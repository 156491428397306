import { type Enum } from '@webapp/utils'

export const PurchaseOrderSearchCategories = {
  PO: 'po',
  ORDER_ITEM: 'orderitem',
  REQUESTER: 'requester',
  VENDOR: 'vendor',
  PURCHASER: 'purchaser',
} as const

export type PurchaseOrderSearchCategory = Enum<
  typeof PurchaseOrderSearchCategories
>
