import { type IntlShape } from 'react-intl'
import * as Yup from 'yup'

import { type CustomFieldValue } from '@webapp/models'
import { type CustomFieldType, CustomFieldTypes } from '@webapp/models/enums'
import messages from './messages'
import { useProcurifyIntl } from '../useProcurifyIntl'

const generateCustomFieldValueSchemaByType = (type: CustomFieldType) => {
  switch (type) {
    case CustomFieldTypes.TEXT:
      return Yup.string().nullable()
    case CustomFieldTypes.DATE:
      return Yup.date().nullable()
    case CustomFieldTypes.CHECKBOX:
      return Yup.boolean().nullable()
    case CustomFieldTypes.DROPDOWN:
      return Yup.object().nullable()
  }
}
/**
 * @param tempIntl this is to support legacy Class based form validations that can not use intl hooks
 */
export function useCustomFieldValidationSchema(
  tempIntl?: IntlShape,
  options?: {
    ignoreIsRequired?: boolean
    customRequiredMessage?: string
  }
) {
  const intl = tempIntl || useProcurifyIntl()

  return Yup.array().of(
    Yup.lazy((customField: CustomFieldValue) => {
      let valueSchema = generateCustomFieldValueSchemaByType(
        customField.field.field_type
      )

      if (customField.field.is_required && !options?.ignoreIsRequired) {
        const requiredMessage = options?.customRequiredMessage
          ? options?.customRequiredMessage
          : intl.formatMessage(messages.fieldIsRequiredMessage, {
              name:
                customField.field.name.length > 36
                  ? intl.formatMessage(messages.thisField)
                  : customField.field.name,
            })

        valueSchema = valueSchema.required(requiredMessage)
      }

      return Yup.object<CustomFieldValue>({
        value: valueSchema,
      })
    })
  )
}
