import { QueryClient } from 'react-query'
import type { DefaultOptions } from 'react-query'

export const defaultOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
  },
  mutations: {
    retry: false,
  },
}

export const queryClient = new QueryClient({
  defaultOptions,
})

export { defaultOptions as queryClientDefaultOptions }
