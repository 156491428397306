import { type Enum } from '@webapp/utils'

export const CustomFieldTypes = {
  TEXT: 't',
  DATE: 'd',
  DROPDOWN: 'm',
  CHECKBOX: 'b',
} as const

export type CustomFieldType = Enum<typeof CustomFieldTypes>
