import * as React from 'react'

import { ModalContextConsumer } from './ModalContext'

export interface IModalRootProps {}

export const ModalRoot: React.FC<IModalRootProps> = React.memo((props) => {
  return (
    <ModalContextConsumer>
      {({ component: Component, componentProps, hideModal }) =>
        Component ? (
          <Component onDismiss={hideModal} {...componentProps} />
        ) : null
      }
    </ModalContextConsumer>
  )
})

ModalRoot.defaultProps = {}
ModalRoot.displayName = 'ModalRoot'
