import { useSelector } from 'react-redux'

import { type Permissions } from '@webapp/models/enums'
import { makeSelectPermissions } from '@webapp/stores/global'
import { hasPermission } from '@webapp/utils/permissions'

export const useCheckPermission = (permission: Permissions) => {
  const permissions = useSelector(makeSelectPermissions()) || []

  return hasPermission(permissions, permission)
}
