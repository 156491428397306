import * as React from 'react'

import styled from 'styled-components'

interface IAppStyledProps {}

export const AppStyled = styled.div<IAppStyledProps>`
  height: 100%;
  display: grid;

  grid-template-rows: ${(props) => props.theme.typography.pxToRem(72)};
  grid-template-areas:
    'header header'
    'content content';
`
