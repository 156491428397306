import styled from 'styled-components'

export interface INavigationLinkContainerStyledProps {
  hasNavigationSubs?: 0 | 1 | boolean
}

export const NavigationLinkContainerStyled = styled.div<INavigationLinkContainerStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};
  transition: ease width 0.1s;

  &.has-navigation-subs {
    .NavigationExpander {
      top: 0;
      width: 0;
      left: ${(props) =>
        props.theme.typography.pxToRem(props.theme.navigation.collapsedWidth)};
      bottom: 0;
      position: absolute;
      background-color: ${(props) => props.theme.colors.offwhite};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
      &.is-collapsed-open {
        z-index: 1;

        .NavigationExpander {
          z-index: 1;
          width: ${(props) =>
            props.theme.typography.pxToRem(props.theme.navigation.fullWidth)};
          border-right: 1px solid
            ${(props) => props.theme.colors.content.border};
        }
      }
      &:hover {
        z-index: 2;

        .NavigationExpander {
          z-index: 2;
          width: ${(props) =>
            props.theme.typography.pxToRem(props.theme.navigation.fullWidth)};
          border-right: 1px solid
            ${(props) => props.theme.colors.content.border};
        }
      }
    }
  }
`
