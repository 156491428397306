import { type Enum } from '../../utils/enums'

export const enum BillItemsValidationErrorCodeTypes {
  COST_EXCEEDS_RECEIVED_AMOUNT = 'cost_exceeds_received_amount',
  QTY_EXCEEDS_RECEIVED_AMOUNT = 'qty_exceeds_received_amount',
  UNIT_COST_VARIANCE = 'item_unit_costs_variance_error',
  UNASSIGNED_COST_ALLOCATION = 'bill_item_unassigned_cost_allocation',
}

export type BillItemsValidationErrorCodeType = Enum<
  typeof BillItemsValidationErrorCodeTypes
>
