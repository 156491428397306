import { em, rgba, shade } from 'polished'
import styled from 'styled-components'

import { type IBaseButtonProps } from '../BaseButton'
import { IconStyled } from '@ui/elements/Icon'
import { IconComponentStyled } from '@ui/elements/Icon/styles/IconComponentStyled'
import { boxShadow } from '@ui/utils/boxShadow'
import { userSelectNone } from '@ui/utils/userSelectNone'

export const BaseButtonStyled = styled.button<IBaseButtonProps>`
  font-family: ${(props) => props.theme.fontFamily.body};
  font-size: ${(props) =>
    props.theme.typography.pxToRem(props.theme.fontSizes.button)};
  font-style: normal;
  font-weight: ${(props) => `${props.theme.fontWeights.bold}`};

  line-height: ${(props) => `${props.theme.fontSizes.button}px`};

  position: relative;
  display: inline-flex;
  overflow: hidden;

  min-height: ${(props) => `${em(38, props.theme.fontSizes.button)}`};
  min-width: ${(props) => `${em(120, props.theme.fontSizes.button)}`};

  margin: 0;
  padding: ${(props) => `${em(12, props.theme.fontSizes.button)}
  ${em(24, props.theme.fontSizes.button)}`};

  outline: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;

  text-align: center;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;

  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s,
    color 0.15s;

  color: ${(props) => props.theme.colors.text.primary};
  background-color: ${(props) => props.theme.colors.white};
  border-color: ${(props) => props.theme.colors.text.primary};

  box-shadow: ${(props) => props.theme.shadows[1]};
  ${userSelectNone()};

  & + & {
    margin-left: 0.75em;
  }

  &:visited {
    color: ${(props) => props.theme.colors.text.primary};
  }

  &:hover {
    background-color: ${(props) => shade(0.05, props.theme.colors.white)};
    ${boxShadow(0, '2px', '5px', 0, rgba(0, 0, 0, 0.2))}
  }

  &:active {
    color: ${(props) => props.theme.colors.text.primary};
    background-color: ${(props) => shade(0.2, props.theme.colors.white)};
    ${boxShadow(0, '2px', '3px', 0, rgba(0, 0, 0, 0.15))}
  }

  &:focus-visible {
    border-width: 2px;
    border-color: ${(props) => props.theme.colors.focus};
  }

  > * {
    margin-right: 1rem;
  }

  > :last-child,
  > .last {
    margin-right: 0;
  }

  > ${/* sc-selector */ IconStyled}, > ${IconComponentStyled} {
    transform: scale(1.25);
    margin-right: 0.5em;
  }

  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  &[disabled] {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.text.inverse};
    background-color: ${(props) => props.theme.colors.disabled};
    border-color: ${(props) => props.theme.colors.disabled};
    box-shadow: none;

    &:hover {
      color: ${(props) => props.theme.colors.text.inverse};
      background-color: ${(props) => props.theme.colors.disabled};
      border-color: ${(props) => props.theme.colors.disabled};
      box-shadow: none;
    }
    &:active {
      color: ${(props) => props.theme.colors.text.inverse};
      background-color: ${(props) => props.theme.colors.disabled};
      border-color: ${(props) => props.theme.colors.disabled};
      box-shadow: none;
    }
  }
`
