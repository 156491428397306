import styled from 'styled-components'

import { applyButtonColorCSS } from './utils'
import { BaseButton } from '../BaseButton'

export const PrimaryButtonStyled = styled(BaseButton)`
  ${(props) =>
    applyButtonColorCSS(
      props.theme.colors.button.primary.background,
      undefined,
      undefined,
      props.theme.colors.button.primary.backgroundHover
    )}
`
