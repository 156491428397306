import * as React from 'react'

import { NotificationButtonStyled } from './styles/NotificationButtonStyled'
import { NotificationContainerStyled } from './styles/NotificationContainerStyled'
import { NotificationIconStyled } from './styles/NotificationIconStyled'
import { NotificationTextStyled } from './styles/NotificationTextStyled'
import { NotificationTitleStyled } from './styles/NotificationTitleStyled'
import { type NotificationType } from './types'
import { Icon, type IconTypes } from '../Icon'
import { Space } from '../Space'

export interface INotificationProps {
  children?: React.ReactNode
  type: NotificationType
  title: React.ReactNode
  onDismiss?: () => void
}

const icons = {
  error: 'close',
  info: 'notification',
  success: 'approval',
  warning: 'attention',
} as Record<NotificationType, IconTypes>

export const Notification = ({
  type,
  title,
  children,
  onDismiss,
}: INotificationProps) => (
  <NotificationContainerStyled>
    <NotificationIconStyled type={type}>
      <Icon size={16} icon={icons[type]} />
    </NotificationIconStyled>
    <Space size='xsmall'>
      <Space direction='horizontal' align='center' justify='space-between'>
        <NotificationTitleStyled>{title}</NotificationTitleStyled>
        <NotificationButtonStyled onClick={onDismiss}>
          <Icon icon='times' size={11} />
        </NotificationButtonStyled>
      </Space>
      <NotificationTextStyled>{children}</NotificationTextStyled>
    </Space>
  </NotificationContainerStyled>
)
