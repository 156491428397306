import * as React from 'react'

import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  0% {transform: rotate(0deg)}
  50% {transform: rotate(180deg)}
  100% {transform: rotate(360deg)}
`

export interface ICircleLoaderProps {
  size?: number
  sizeUnit?: string
  color?: string
  strokeWidth?: number
}

export const CircleLoader = styled.div<ICircleLoaderProps>`
  background: transparent !important;
  width: ${(props) => `${props.size}${props.sizeUnit}`};
  height: ${(props) => `${props.size}${props.sizeUnit}`};
  border-radius: 100%;
  border: ${(props) => `${props.strokeWidth}px solid`};
  border-color: ${(props) => props.color};
  border-bottom-color: transparent;
  display: inline-block;
  animation: ${animation} 0.75s 0s infinite linear;
  animation-fill-mode: both;
`

CircleLoader.defaultProps = {
  color: '#FFF',
  size: 18,
  sizeUnit: 'px',
  strokeWidth: 2,
}
