import { type PropsFor, spacing, style, typography } from '@material-ui/system'
import { palette } from '@procurify/ui-theme'
import styled, { css } from 'styled-components'

const as = style({
  cssProperty: false,
  prop: 'as',
  themeKey: 'typography',
})

import { SubheaderStyled } from './SubheaderStyled'
import { type IHeaderProps } from '../Header'

const HeaderStyled = styled.h1<IHeaderProps & PropsFor<typeof as>>`
  margin: 0;

  h5& ${SubheaderStyled} {
    font-size: ${(props) => props.theme.typography.pxToRem(14)};
  }

  && {
    ${(props) => {
      if (props.gutter) {
        return `margin-bottom: 1em;`
      }
    }}

    ${(props) => {
      if (props.icon) {
        return css`
          text-align: center;
        `
      }
    }}
  }

  ${as}
  ${typography}
  ${spacing}
  ${palette}
`

HeaderStyled.defaultProps = {
  as: 'h1',
}

export { HeaderStyled }
