import { defineMessages } from 'react-intl'

const scope = `procurify.models.enums.SpendInsightsVisuals`

export default defineMessages({
  spendTrendName: {
    id: `${scope}.spendTrendName`,
    defaultMessage: 'Spend trend',
  },
  top5ItemsName: {
    id: `${scope}.top5ItemsName`,
    defaultMessage: 'Top 5 items by spend',
  },
  top5VendorsName: {
    id: `${scope}.top5VendorsName`,
    defaultMessage: 'Top 5 vendors by spend',
  },
  top5DepartmentsName: {
    id: `${scope}.top5DepartmentsName`,
    defaultMessage: 'Top 5 departments by spend',
  },
  paymentTermsByCommittedSpendName: {
    id: `${scope}.paymentTermsByCommittedSpendName`,
    defaultMessage: 'Payment terms by spend',
  },
  approvedRFOValuesName: {
    id: `${scope}.approvedRFOValuesName`,
    defaultMessage: 'Approved RFO values',
  },
  approvedRFOsName: {
    id: `${scope}.approvedRFOsName`,
    defaultMessage: 'Approved RFOs',
  },
  createdPOsName: {
    id: `${scope}.createdPOsName`,
    defaultMessage: 'Created POs',
  },
  rejectedRFOsName: {
    id: `${scope}.rejectedRFOs`,
    defaultMessage: 'Rejected RFOs',
  },
  rejectedRFOValuesName: {
    id: `${scope}.rejectedRFOValuesName`,
    defaultMessage: 'Rejected RFO values',
  },
})
