import { type Enum } from '@webapp/utils'

export const SpendInsightsLiveBoards = {
  ANNUALLY: '33c3f1ff-4a3e-4c60-82d9-a0c254732e6a',
  QUARTERLY: '933ed48d-a4dc-4483-a20d-0a879b300f55',
  MONTHLY: '7c94b26d-6836-4a10-b83c-85a6a3d67184',
}
export type SpendInsightsLiveBoard = Enum<typeof SpendInsightsLiveBoards>

export const SpendInsightsAnnualVisuals = {
  SPEND_TREND: '03245a16-3908-4d1e-a00e-ff75d5828d2e',
  TOP_5_ITEMS: 'cdec7f4a-cfb3-4212-9d78-c44d174bba7c',
  TOP_5_VENDORS: '4c98baa6-ad57-48fe-9f0f-28c455f4db5d',
  PAYMENT_TERMS_BY_COMMITTED_SPEND: '5c9c899d-d477-402c-b590-be1682491562',
  TOP_5_DEPARTMENTS: '934de08e-ede7-4280-a685-5802ca5534db',
  APPROVED_RFO_VALUES: '75508222-2dc3-4842-ba0e-95a1a7c823db',
  APPROVED_RFOS: 'b5beffc6-a7dc-43ed-96da-65bd01f2b675',
  CREATED_POS: '9d767b54-778c-43d6-ae49-9ffd3e352303',
  REJECTED_RFOS: '67df3551-77f7-48ea-9b8b-a86dd3ee66e8',
  REJECTED_RFO_VALUES: 'b9644a2b-c8f7-40b5-ac74-79133f490bc3',
} as const
export type SpendInsightsAnnualVisual = Enum<typeof SpendInsightsAnnualVisuals>

export const SpendInsightsQuarterlyVisuals = {
  SPEND_TREND: '11f301e8-341c-4a8a-b24f-a4a4adad9da6',
  /* TOP_5_ITEMS: 'cdec7f4a-cfb3-4212-9d78-c44d174bba7c',
  TOP_5_VENDORS: '4c98baa6-ad57-48fe-9f0f-28c455f4db5d',
  PAYMENT_TERMS_BY_COMMITTED_SPEND: '5c9c899d-d477-402c-b590-be1682491562',
  TOP_5_DEPARTMENTS: '934de08e-ede7-4280-a685-5802ca5534db',
  APPROVED_RFO_VALUES: '75508222-2dc3-4842-ba0e-95a1a7c823db',
  APPROVED_RFOS: 'b5beffc6-a7dc-43ed-96da-65bd01f2b675',
  CREATED_POS: '9d767b54-778c-43d6-ae49-9ffd3e352303',
  REJECTED_RFOS: '67df3551-77f7-48ea-9b8b-a86dd3ee66e8',
  REJECTED_RFO_VALUES: 'b9644a2b-c8f7-40b5-ac74-79133f490bc3',*/
} as const
export type SpendInsightsQuarterlyVisual = Enum<
  typeof SpendInsightsQuarterlyVisuals
>

export const SpendInsightsMonthlyVisuals = {
  SPEND_TREND: 'a3407bf7-6ee4-482e-b896-0911efc6c59e',
  /* TOP_5_ITEMS: 'cdec7f4a-cfb3-4212-9d78-c44d174bba7c',
  TOP_5_VENDORS: '4c98baa6-ad57-48fe-9f0f-28c455f4db5d',
  PAYMENT_TERMS_BY_COMMITTED_SPEND: '5c9c899d-d477-402c-b590-be1682491562',
  TOP_5_DEPARTMENTS: '934de08e-ede7-4280-a685-5802ca5534db',
  APPROVED_RFO_VALUES: '75508222-2dc3-4842-ba0e-95a1a7c823db',
  APPROVED_RFOS: 'b5beffc6-a7dc-43ed-96da-65bd01f2b675',
  CREATED_POS: '9d767b54-778c-43d6-ae49-9ffd3e352303',
  REJECTED_RFOS: '67df3551-77f7-48ea-9b8b-a86dd3ee66e8',
  REJECTED_RFO_VALUES: 'b9644a2b-c8f7-40b5-ac74-79133f490bc3',*/
} as const
export type SpendInsightsMonthlyVisual = Enum<
  typeof SpendInsightsMonthlyVisuals
>

export const SpendInsightsLinkOutTables = {
  RFO: '98bf2222-8102-47cc-8369-afffb4863b62',
  PO: 'e84ba431-7489-4c46-a398-ee9c2f1521a4',
  SPEND: '2780bffe-f624-4e8c-b589-9e1970d155a6',
  BUDGETS: 'e90ee986-cb69-41be-b87e-9c157d03245d',
} as const
export type SpendInsightsLinkOutTable = Enum<typeof SpendInsightsLinkOutTables>

export const SpendInsightsColumnNames = {
  DEPARTMENT_ID: 'Department Id',
  DATETIME: 'Datetime',
} as const
export type SpendInsightsColumnName = Enum<typeof SpendInsightsColumnNames>
