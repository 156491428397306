import * as React from 'react'

import { type IBaseTextProps } from './BaseText'
import { SubTextStyled } from './styles/SubTextStyled'

export const SubText = (props: React.PropsWithChildren<IBaseTextProps>) => (
  <SubTextStyled {...props} />
)

SubText.displayName = 'SubText'
