import * as React from 'react'

import { TextLoader } from '@webapp/core/components/ContentLoader'
import { NavigationLoaderStyled } from '../styles/NavigationLoaderStyled'
import { NavigationStyled } from '../styles/NavigationStyled'

export const NavigationLoader = React.memo(() => {
  return (
    <NavigationStyled>
      <NavigationLoaderStyled>
        <div className='nav-icon'>
          <TextLoader width={20} height={20} />
        </div>
        <div className='nav-text'>
          <TextLoader height={12} />
        </div>
      </NavigationLoaderStyled>
      <NavigationLoaderStyled>
        <div className='nav-icon'>
          <TextLoader width={20} height={20} />
        </div>
        <div className='nav-text'>
          <TextLoader height={12} />
        </div>
      </NavigationLoaderStyled>
      <NavigationLoaderStyled>
        <div className='nav-icon'>
          <TextLoader width={20} height={20} />
        </div>
        <div className='nav-text'>
          <TextLoader height={12} />
        </div>
      </NavigationLoaderStyled>
      <NavigationLoaderStyled>
        <div className='nav-icon'>
          <TextLoader width={20} height={20} />
        </div>
        <div className='nav-text'>
          <TextLoader height={12} />
        </div>
      </NavigationLoaderStyled>
    </NavigationStyled>
  )
})
