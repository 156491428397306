import { useIntl } from 'react-intl'

interface UseFormatPercentageOptions {
  useSystemLocale?: boolean
}

const DEFAULT_FORMAT_OPTIONS = {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}

export const useFormatPercent = ({
  useSystemLocale = false,
}: UseFormatPercentageOptions = {}) => {
  const intl = useIntl()

  const formatNumber = (value, formatOptions) => {
    if (useSystemLocale) {
      return intl.formatNumber(value, formatOptions)
    } else {
      return new Intl.NumberFormat('en', formatOptions).format(value)
    }
  }

  return (
    value: string | number,
    minimumFractionDigits?: number,
    maximumFractionDigits?: number,
    fallbackValue: string = formatNumber(0, DEFAULT_FORMAT_OPTIONS)
  ) => {
    try {
      const formatOptions = {
        ...DEFAULT_FORMAT_OPTIONS,
        ...(minimumFractionDigits !== null &&
          minimumFractionDigits !== undefined && { minimumFractionDigits }),
        ...(maximumFractionDigits !== null &&
          maximumFractionDigits !== undefined && { maximumFractionDigits }),
      }

      if (
        formatOptions.minimumFractionDigits >
        formatOptions.maximumFractionDigits
      ) {
        throw new Error(
          'maximumFractionDigits must not be less than minimumFractionDigits'
        )
      }

      return formatNumber(value, formatOptions)
    } catch (e) {
      console.error(e)
      return fallbackValue
    }
  }
}
