import * as React from 'react'

import * as ReactDOM from 'react-dom'

import { IS_DEV } from './configs/env'
import { AuthenticationApp } from './modules/app/AuthenticationApp'
import './modules/app/scss/critical.scss'

if (IS_DEV) {
  import('@procurify/webapp/src/tests/browser').then(({ worker }) => {
    worker.start({
      onUnhandledRequest: 'bypass',
    })
  })
}

export const appRoot = document.getElementById('authentication-root')

const render = () => {
  ReactDOM.render(<AuthenticationApp />, appRoot)
}

render()
