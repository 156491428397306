import * as React from 'react'

import { IconStyled } from '@procurify/ui'
import { TextStyled } from '@procurify/ui'
import { darken, shade, tint } from 'polished'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export interface INavigationLinkStyledProps {
  id: string
  to: string
  legacy: boolean
  className: string
  onClick?: () => void
  onLinkClick?: () => void
}

export const NavigationLinkStyled = styled(
  ({
    id,
    to,
    legacy,
    className,
    children,
    onClick = () => undefined,
    onLinkClick = () => undefined,
  }: React.PropsWithChildren<INavigationLinkStyledProps>) => {
    if (!to) {
      return (
        <button
          id={id}
          className={`${className} nav-group-btn`}
          onClick={onClick}
        >
          {children}
        </button>
      )
    }

    return legacy ? (
      <a id={id} className={className} href={to} onClick={onLinkClick}>
        {children}
      </a>
    ) : (
      <Link id={id} to={to} className={className} onClick={onLinkClick}>
        {children}
      </Link>
    )
  }
)<INavigationLinkStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  position: relative;

  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  height: ${(props) =>
    props.theme.typography.pxToRem(props.theme.navigation.linkHeight)};
  padding: ${(props) => props.theme.space.xsmall};
  border-radius: 4px;

  margin-top: ${(props) => props.theme.typography.pxToRem(4)};

  &.nav-group-btn {
    background: inherit;
    border: 0;
    font-family: inherit;
    width: 100%;
  }

  &:focus-visible {
    z-index: 2;
    outline: ${(props) =>
      `${props.theme.typography.pxToRem(2)} solid ${props.theme.colors.focus}`};
  }

  ${TextStyled} {
    font-size: ${(props) => props.theme.typography.pxToRem(14)};
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
  }

  .nav-icon {
    width: ${(props) => props.theme.typography.pxToRem(24)};
    vertical-align: middle;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${(props) => props.theme.typography.pxToRem(8)};

    transition: transform 0.15s;

    @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
      transform: translateX(${(props) => props.theme.typography.pxToRem(20)});
    }
  }

  .chevron-container {
    margin-right: ${(props) => props.theme.typography.pxToRem(8)};
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    width: ${(props) => props.theme.typography.pxToRem(8)};
    ${IconStyled} {
      color: ${(props) => shade(0.3, props.theme.colors.lightGrey)};
    }
  }

  && {
    color: ${(props) => props.theme.colors.text.primary};
  }

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.colors.offwhite)};
  }

  &.active {
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    background-color: ${(props) => props.theme.colors.selected};
  }

  &.is-open {
    background-color: ${(props) => props.theme.colors.white};

    &:hover {
      background-color: ${(props) => darken(0.05, props.theme.colors.offwhite)};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      background-color: transparent;

      &.is-collapsed-open {
        background-color: ${(props) => props.theme.colors.white};
      }

      &:hover {
        background-color: ${(props) =>
          darken(0.05, props.theme.colors.offwhite)};
      }
    }
  }

  .navigation-tooltip-trigger {
    display: none;

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`
