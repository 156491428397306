import Axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios'
import QueryString from 'query-string'

/**
 *
 *
 * @export
 * @interface IApiPagination
 * @description Current version pagination
 */
export interface IApiPagination {
  count?: number
  current_page?: number
  next?: string | null
  num_pages?: number
  page_size?: number
  previous?: string | null
}

export interface ApiClientConfig extends AxiosRequestConfig {
  /**
   * Determine the array param format
   *
   * `bracket` - `foo[]=1&foo[]=2`
   *
   * `none` - `foo=1&foo=2`
   *
   * @default "bracket"
   */
  arrayParamFormat?: 'bracket' | 'none'
}

/*
 * Description: Type for ORDER/TRAVEL/EXPENSE details to show user permisisons
 */
export interface OrderExpenseTravelAccessPermissions {
  can_edit: boolean
  can_delete: boolean
  can_approve: boolean
}

export interface OrderDetailsPermissions
  extends OrderExpenseTravelAccessPermissions {
  has_pending_items: boolean
}

export interface IMetadata {
  counts?: object
  pagination?: IApiPagination
  permissions?: OrderExpenseTravelAccessPermissions
}

/**
 * @export
 * @interface IApiV1Pagination
 * @deprecated Api V1 pagination
 */
export interface IApiV1Pagination {
  count?: number
  next?: string | null
  limit?: number
  offset?: number
  prev?: string | null
  pages?: number[]
  page?: number
}

export interface IApiV1Response<T = any> {
  code: number
  data: T
  description: string
  metadata?: IMetadata
  paging?: IApiV1Pagination
  success: boolean
}

export interface IApiV2Response<T = any> {
  data: T
  metadata?: IMetadata
}

export interface IApiV3Response<T = any, M = IMetadata> {
  data: T
  metadata?: M
}

export interface BaseError {
  code: string
  message: string
}

export interface CodeError {
  code: string
  field: null | string
  message: string
}

export interface IApiV1Error {
  data: any
  code: number
  description: string
  success: boolean
}

export interface IApiV3Error<T = any> {
  data: T
  errors: { [name: string]: BaseError }
  errors_with_codes?: CodeError[]
  metadata?: IMetadata
}

/**
 *
 * @interface IApiError
 * @extends {AxiosError}
 * @template T
 * @description Interface for API errors
 * @example .catch((error: IApiError<Vendor>) => {}) => "data" in response.data will be of the type provided, or "any" by default
 */
export interface IApiError<T = any> extends AxiosResponse<IApiV3Error<T>> {}

/**
 *
 * @interface IApiResponse
 * @extends {IApiV3Response}
 * @template T
 * @description Interface for API response
 * Api client will shoehorn response into IApiV3Response
 */
export interface IApiResponse<T = any, M = any> extends IApiV3Response<T, M> {}

interface IApi {
  request: <T = any, R = T>(config: ApiClientConfig) => Promise<IApiResponse<R>>
  get: <T = any, R = T>(
    url: string,
    config?: ApiClientConfig
  ) => Promise<IApiResponse<R>>
  delete: <T = any, R = T>(
    url: string,
    config?: ApiClientConfig
  ) => Promise<IApiResponse<R>>
  head: <T = any, R = T>(
    url: string,
    config?: ApiClientConfig
  ) => Promise<IApiResponse<R>>
  post: <T = any, R = T>(
    url: string,
    data?: T,
    config?: ApiClientConfig
  ) => Promise<IApiResponse<R>>
  put: <T = any, R = T>(
    url: string,
    data?: T,
    config?: ApiClientConfig
  ) => Promise<IApiResponse<R>>
  patch: <T = any, R = T>(
    url: string,
    data?: T,
    config?: ApiClientConfig
  ) => Promise<IApiResponse<R>>
}

/**
 *
 *
 * @class Api
 * @description Creates an instance of an axios client
 * and contains calls to that instance
 */
export class Api implements IApi {
  readonly cancellable = {
    get: <T = any, R = T, M = any>(
      url: string,
      config: ApiClientConfig = {}
    ): [Promise<IApiResponse<R, M>>, () => void] => {
      const cancelSource = Axios.CancelToken.source()

      return [
        this.client.get<T, AxiosResponse<R>>(url, {
          cancelToken: cancelSource.token,
          ...config,
        }),
        () => {
          cancelSource.cancel()
        },
      ]
    },
    post: <T = any, R = T, M = any>(
      url: string,
      data: any,
      config: ApiClientConfig = {}
    ): [Promise<IApiResponse<R, M>>, () => void] => {
      const cancelSource = Axios.CancelToken.source()

      return [
        this.client.post<T, AxiosResponse<R>>(url, data, {
          cancelToken: cancelSource.token,
          ...config,
        }),
        () => {
          cancelSource.cancel()
        },
      ]
    },
  }

  constructor(private client: AxiosInstance) {}

  extendConfig = (config: ApiClientConfig): AxiosRequestConfig => {
    const { arrayParamFormat = 'bracket', ...axiosRequestConfig } = config || {}

    return {
      ...axiosRequestConfig,
      paramsSerializer: (paramObj) => {
        /**
         * This is the default param serializer axios uses
         */
        return QueryString.stringify(paramObj, {
          arrayFormat: arrayParamFormat,
        })
      },
    }
  }

  readonly request = <T = any, R = T, M = any>(
    config: ApiClientConfig
  ): Promise<IApiResponse<R, M>> => {
    return this.client.request<T, AxiosResponse<R>>(this.extendConfig(config))
  }

  readonly get = <T = any, R = T, M = any>(
    url: string,
    config?: ApiClientConfig
  ): Promise<IApiResponse<R, M>> => {
    return this.client.get<T, AxiosResponse<R>>(url, this.extendConfig(config))
  }

  readonly delete = <T = any, R = T>(
    url: string,
    config?: ApiClientConfig
  ): Promise<IApiResponse<R>> => {
    return this.client.delete<T, AxiosResponse<R>>(
      url,
      this.extendConfig(config)
    )
  }

  readonly head = <T = any, R = T>(
    url: string,
    config?: ApiClientConfig
  ): Promise<IApiResponse<R>> => {
    return this.client.head<T, AxiosResponse<R>>(url, this.extendConfig(config))
  }

  readonly post = <T = any, R = T>(
    url: string,
    data?: T,
    config?: ApiClientConfig
  ): Promise<IApiResponse<R>> => {
    return this.client.post<T, AxiosResponse<R>>(
      url,
      data,
      this.extendConfig(config)
    )
  }

  readonly put = <T = any, R = T>(
    url: string,
    data?: T,
    config?: ApiClientConfig
  ): Promise<IApiResponse<R>> => {
    return this.client.put<T, AxiosResponse<R>>(
      url,
      data,
      this.extendConfig(config)
    )
  }

  readonly patch = <T = any, R = T>(
    url: string,
    data?: T,
    config?: ApiClientConfig
  ): Promise<IApiResponse<R>> => {
    return this.client.patch<T, AxiosResponse<R>>(
      url,
      data,
      this.extendConfig(config)
    )
  }

  readonly options = <T = any, R = T>(
    url: string,
    config?: ApiClientConfig
  ): Promise<IApiResponse<R>> => {
    return this.client.options<T, AxiosResponse<R>>(
      url,
      this.extendConfig(config)
    )
  }
}

export interface IApiRequestParam {
  [key: string]: number | string | boolean
}
