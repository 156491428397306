import * as React from 'react'

import { useSelector } from 'react-redux'

import { type FeatureFlagKey } from '@webapp/services/FeatureFlag'
import { makeSelectFeatureFlag } from '@webapp/stores/global'

export const useFeatureFlag = (featureFlagKey: FeatureFlagKey) => {
  return !!useSelector(makeSelectFeatureFlag(featureFlagKey))
}
