import axios from 'axios'

import { redirectOnSessionExpired } from './redirectOnSessionExpired'
import { V3RequestInterceptor } from './v3'

export const genericApi = axios.create()

genericApi.defaults.xsrfHeaderName = 'X-CSRFToken'
genericApi.defaults.xsrfCookieName = 'csrftoken'

// using v3RequestInterceptor to set headers and get request params
genericApi.interceptors.request.use(V3RequestInterceptor)

genericApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      error.response.message = error.toString()
    }

    throw error.response || error
  }
)

genericApi.interceptors.response.use(null, redirectOnSessionExpired)
