import { Enum } from '../../utils/enums'

export const ExpenseItemStatusEnumValues = [
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied',
]
export const ExpenseItemStatus = Enum(
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied'
)
