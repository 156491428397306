import { type Enum } from '@webapp/utils'

export const Teams = {
  GRANDFATHERED: 'GRANDFATHERED',
  TEAM_RAPPTR: 'TEAM_RAPPTR',
  TEAM_AP: 'TEAM_AP',
  TEAM_SPENDSIGHT: 'TEAM_SPENDSIGHT',
  TEAM_PAYMENTS: 'TEAM_PAYMENTS',
  TEAM_INTEGRATIONS: 'TEAM_INTEGRATIONS',
  TEAM_CLC: 'TEAM_CLC',
  TEAM_ARCHITECTURE: 'TEAM_ARCHITECTURE',
  TEAM_DEVSECOPS: 'TEAM_DEVSECOPS',
  TEAM_MHAUS: 'TEAM_MHAUS',
  PUNCHOUT_BETAS: 'PUNCHOUT_BETAS',
  DESIGN_SYSTEM: 'DESIGN_SYSTEM',
} as const

export type Teams = Enum<typeof Teams>
