import * as React from 'react'

import { type BudgetGroupBudget } from '@webapp/models'
import { useFormatISOStringToDate } from '../useFormatISOStringToDate'
import { useFormatSpendValues } from '../useFormatSpendValues'
import { type BudgetBreakdownBar } from '../useGetBudgetBreakdown'

interface FormattedBudget {
  id: number
  approved: number
  pending: number
  purchased: number
  invoiced: number
  currentSpend: number
  otherPendingRequests: number
  startDateTime?: Date
  endDateTime?: Date
  spendAfterApproval?: number
  variance?: number
  variancePercentage?: number
  budget?: number
  budgetBreakdown?: BudgetBreakdownBar[]
  isOverBudget?: boolean
}

export const useFormatBudget = () => {
  const [budgetToFormat, setBudgetToFormat] =
    React.useState<BudgetGroupBudget>(null)
  const [formattedBudget, setFormattedBudget] =
    React.useState<FormattedBudget>(null)

  const formatSpendValues = useFormatSpendValues()
  const formatISODateStringToDate = useFormatISOStringToDate()

  React.useEffect(() => {
    if (budgetToFormat) {
      const {
        id,
        start_datetime,
        end_datetime,
        approved,
        invoiced,
        pending,
        purchased,
        request_pending_amount,
        amount,
        variance: _variance,
        variance_percentage,
      } = budgetToFormat

      const {
        approvedSpend,
        invoicedSpend,
        pendingSpend,
        purchasedSpend,
        currentSpend,
        otherPendingRequests,
        spendAfterApproval,
        currentRequestSpend,
        budget,
        variance,
        variancePercentage,
        budgetBreakdown,
        isOverBudget,
      } = formatSpendValues(
        approved,
        invoiced,
        pending,
        purchased,
        request_pending_amount,
        amount,
        _variance,
        variance_percentage,
        true
      )

      setFormattedBudget({
        id,
        approved: approvedSpend,
        pending: pendingSpend,
        invoiced: invoicedSpend,
        purchased: purchasedSpend,
        currentSpend,
        otherPendingRequests,
        budget,
        budgetBreakdown,
        variance,
        variancePercentage,
        isOverBudget,
        startDateTime: formatISODateStringToDate(start_datetime, null, true),
        endDateTime: formatISODateStringToDate(end_datetime, null, true),
      })
    } else {
      setFormattedBudget(null)
    }
  }, [budgetToFormat])

  return { budget: formattedBudget, setBudgetToFormat }
}
