import Decimal from 'decimal.js'

import {
  type BudgetBreakdownBar,
  useGetBudgetBreakdown,
} from '../useGetBudgetBreakdown'

export const useFormatSpendValues = () => {
  const getBudgetBreakdown = useGetBudgetBreakdown()

  return (
    approved: string = '0',
    invoiced: string = '0',
    pending: string = '0',
    purchased: string = '0',
    requestPendingAmount: string = '0',
    budget: string = null,
    variance: string = null,
    variancePercentage: string = null,
    showSpendBreakdown: boolean = false
  ): {
    approvedSpend: number
    invoicedSpend: number
    pendingSpend: number
    purchasedSpend: number
    currentSpend: number
    otherPendingRequests: number
    spendAfterApproval: number
    currentRequestSpend: number
    budget: number
    variance: number
    variancePercentage: number
    budgetBreakdown: BudgetBreakdownBar[]
    isOverBudget: boolean
  } => {
    const _zero = new Decimal(0)
    const _approvedSpend = new Decimal(approved)
    const _invoicedSpend = new Decimal(invoiced)
    const _pendingSpend = new Decimal(pending)
    const _purchasedSpend = new Decimal(purchased)
    const _currentRequestSpend = new Decimal(requestPendingAmount)

    const _currentSpend = _purchasedSpend
      .add(_approvedSpend)
      .add(_invoicedSpend)

    const pendingSpendLessCurrentRequestSpend =
      _pendingSpend.minus(_currentRequestSpend)

    const _otherPendingRequests = pendingSpendLessCurrentRequestSpend.lessThan(
      _zero
    )
      ? _zero
      : pendingSpendLessCurrentRequestSpend

    const _spendAfterApproval = _currentSpend.add(_currentRequestSpend)

    const budgetAmount = !!budget ? new Decimal(budget).toNumber() : null
    const varianceAmount = !!variance ? new Decimal(variance).toNumber() : null
    const variancePercentageAmount = !!variancePercentage
      ? new Decimal(variancePercentage).toNumber()
      : null

    const currentSpend = _currentSpend.toNumber()
    const currentRequestSpend = _currentRequestSpend.toNumber()
    const spendAfterApproval = _spendAfterApproval.toNumber()
    const pendingSpend = _pendingSpend.toNumber()
    const otherPendingRequests = _otherPendingRequests.toNumber()
    const approvedSpend = _approvedSpend.toNumber()
    const purchasedSpend = _purchasedSpend.toNumber()
    const invoicedSpend = _invoicedSpend.toNumber()

    const budgetBreakdown = !!budget
      ? getBudgetBreakdown(
          budgetAmount,
          currentSpend,
          currentRequestSpend,
          spendAfterApproval,
          otherPendingRequests,
          approvedSpend,
          purchasedSpend,
          invoicedSpend,
          showSpendBreakdown
        )
      : []

    const isOverBudget = !!varianceAmount ? varianceAmount < 0 : null

    return {
      approvedSpend,
      invoicedSpend,
      pendingSpend,
      purchasedSpend,
      currentSpend,
      otherPendingRequests,
      spendAfterApproval,
      currentRequestSpend,
      budget: budgetAmount,
      variance: varianceAmount,
      variancePercentage: variancePercentageAmount,
      budgetBreakdown,
      isOverBudget,
    }
  }
}
