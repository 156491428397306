import { Permissions } from '@webapp/models/enums'
import { useCheckPermission } from '../useCheckPermission'

export const usePaymentPermissions = () => {
  const canCreatePayments = useCheckPermission(
    Permissions.ACCOUNTS_PAYABLE_CREATE_PAYMENTS
  )
  const canManagePayments = useCheckPermission(
    Permissions.ACCOUNTS_PAYABLE_MANAGE_PAYMENTS
  )
  const canPreparePayments = useCheckPermission(
    Permissions.ACCOUNTS_PAYABLE_PAYMENT_PROCESSING
  )

  return {
    canCreatePayments,
    canManagePayments,
    canPreparePayments,
  }
}
