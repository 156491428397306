import { produce } from 'immer'

import { CREATED_NEW_CARD, UPDATE_COUNTS } from './constants'
import {
  type IPayCardManagementStoreState,
  type PayCardManagementActions,
} from './types'

export const initialState: IPayCardManagementStoreState = {
  tabCounts: {
    active: 0,
    inactive: 0,
    pending: 0,
    unavailable: 0,
    canceled: 0,
  },
  newCardCreated: false,
}

export const reducer = (
  state = initialState,
  action: PayCardManagementActions
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_COUNTS:
        draft.tabCounts = {
          ...draft.tabCounts,
          ...action.counts,
        }
        break
      case CREATED_NEW_CARD:
        draft.newCardCreated = action.newCardCreated
        break
    }
  })
