import { palette } from '@material-ui/system'
import styled from 'styled-components'

import { type PaletteProps } from '@ui/core/types'

export interface ICardMetaStyledProps extends Pick<PaletteProps, 'bgcolor'> {}

export const CardMetaStyled = styled.div<ICardMetaStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  display: flex;
  align-items: center;

  padding: 0.75em 1.25em;
  min-height: ${(props) => props.theme.typography.pxToRem(48)};

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.content.border};
  }

  ${palette};
`
CardMetaStyled.displayName = 'CardMetaStyled'
