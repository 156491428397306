import { type Attachment } from '@webapp/models'
import { ApiClient } from '@webapp/services/api'

export const ATTACHMENTS_URL = '/api/v3/attachments/'

export interface UpdateAttachmentSchema {
  name: Attachment['name']
}

export const updateAttachment = (
  uuid: Attachment['uuid'],
  data: UpdateAttachmentSchema
) =>
  ApiClient.patch<UpdateAttachmentSchema, Attachment>(
    `${ATTACHMENTS_URL}${uuid}/`,
    data
  )

export const deleteAttachment = (uuid: Attachment['uuid']) =>
  ApiClient.delete<{}, Attachment>(`${ATTACHMENTS_URL}${uuid}/`)
