import * as React from 'react'

import { SheetStyled } from '@procurify/ui'
import styled from 'styled-components'

interface IAppContentWrapperProps {}

export const AppContentWrapper = styled.div<IAppContentWrapperProps>`
  grid-area: content;

  display: grid;

  grid-template-columns: ${(props) =>
    `${props.theme.typography.pxToRem(
      props.theme.navigation.fullWidth
    )} minmax(${props.theme.typography.pxToRem(
      1180 - props.theme.navigation.fullWidth
    )}, auto)`};
  grid-template-areas: 'navigation content';

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl + 'px'}) {
    grid-template-columns: ${(props) =>
      `${props.theme.typography.pxToRem(
        props.theme.navigation.collapsedWidth
      )} minmax(${props.theme.typography.pxToRem(
        1180 - props.theme.navigation.collapsedWidth
      )}, auto)`};
  }

  ${SheetStyled} {
    margin-top: ${({ theme }) => theme.typography.pxToRem(72)};
  }
`
