import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import { get } from 'lodash'
import { v4 } from 'uuid'

import { redirectOnSessionExpired } from './redirectOnSessionExpired'
import { toast } from '../../components/Notification'

export const v3 = axios.create()

export const V3RequestInterceptor = (request) => {
  // Add a correlation ID to every request made to Procurify's BE
  request.headers['X-Correlation-Id'] = v4()
  request.headers['X-Procurify-Client'] = 'web:react'

  return request
}

v3.defaults.xsrfHeaderName = 'X-CSRFToken'
v3.defaults.xsrfCookieName = 'csrftoken'

v3.interceptors.request.use(V3RequestInterceptor)

export const V3ResponseInterceptor = (response) => response.data
export const V3ResponseErrorInterceptor = (error) => {
  if (get(error, 'response.status') === HttpStatus.INTERNAL_SERVER_ERROR) {
    toast.error({
      children: 'An unexpected error has occured',
      title: 'Error',
    })
  }

  if (error.response) {
    error.response.message = error.toString()
  }

  throw error.response || error
}

v3.interceptors.response.use(V3ResponseInterceptor, V3ResponseErrorInterceptor)
v3.interceptors.response.use(null, redirectOnSessionExpired)
