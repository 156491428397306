import * as React from 'react'

import { CardFooterStyled } from './styles/CardFooterStyled'

class ICardFooterProps {}

export const CardFooter = ({
  children,
}: React.PropsWithChildren<ICardFooterProps>) => (
  <CardFooterStyled>{children}</CardFooterStyled>
)

CardFooter.displayName = 'CardFooter'
