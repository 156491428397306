import { type Enum } from '@webapp/utils/enums'

export const PaymentErrorCodeTypes = {
  ACCOUNT_CLOSED: 'ACCOUNT_CLOSED',
  ACCOUNT_FROZEN: 'ACCOUNT_FROZEN',
  ACCOUNT_INVALID: 'ACCOUNT_INVALID',
  ACCOUNT_NUMBER_INVALID: 'ACCOUNT_NUMBER_INVALID',
  BALANCE_INSUFFICIENT: 'BALANCE_INSUFFICIENT',
  BANK_ACCOUNT_RESTRICTED: 'BANK_ACCOUNT_RESTRICTED',
  BANK_OWNERSHIP_CHANGED: 'BANK_OWNERSHIP_CHANGED',
  COULD_NOT_PROCESS: 'COULD_NOT_PROCESS',
  DECLINED: 'DECLINED',
  GENERIC_ERROR: 'GENERIC_ERROR',
  INCORRECT_ACCOUNT_HOLDER_NAME: 'INCORRECT_ACCOUNT_HOLDER_NAME',
  INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
  INVALID_ACCOUNT_NUMBER: 'INVALID_ACCOUNT_NUMBER',
  INVALID_CURRENCY: 'INVALID_CURRENCY',
  ROUTING_NUMBER_INVALID: 'ROUTING_NUMBER_INVALID',
  NO_ACCOUNT: 'NO_ACCOUNT',
  RESOURCE_MISSING: 'RESOURCE_MISSING',
  STRIPE_CONNECT_BAD_REQUEST: 'STRIPE_CONNECT_BAD_REQUEST',
  INCORRECT_BANKING_INFORMATION: 'INCORRECT_BANKING_INFORMATION',
  OTHER: 'OTHER',
} as const

export type PaymentErrorCodeType = Enum<typeof PaymentErrorCodeTypes>
