import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'

import { type Timezones } from '@webapp/models'
import { makeSelectHeadquartersTimezone } from '@webapp/stores/global'

export const useFormatISOStringToDate = () => {
  const headquartersTimezone = useSelector(
    makeSelectHeadquartersTimezone()
  ) as Timezones

  return (
    isoDateTimeString: string,
    timezone: Timezones,
    useHeadQuarterTimezone: boolean = false
  ) => {
    if (!isoDateTimeString || (!useHeadQuarterTimezone && !timezone)) {
      console.error(
        'isoDateTimeString and timezone values are required arguments'
      )
    }

    const { day, month, year, hour, minute, second } = DateTime.fromISO(
      isoDateTimeString,
      { zone: useHeadQuarterTimezone ? headquartersTimezone : timezone }
    ).toObject()

    return new Date(year, month - 1, day, hour, minute, second)
  }
}
