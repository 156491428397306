import * as React from 'react'

import { Space } from '@procurify/ui'

import { TextLoader } from '@webapp/core/components/ContentLoader'
import { AppHeaderLogo } from './components/AppHeaderLogo'
import { AppHeaderLoaderStyled } from './styles/AppHeaderLoaderStyled'

export const AppHeaderLoader = () => {
  return (
    <AppHeaderLoaderStyled>
      <Space direction='horizontal' align='center' justify='space-between'>
        <AppHeaderLogo />

        <TextLoader width={300} height={20} />
      </Space>
    </AppHeaderLoaderStyled>
  )
}

AppHeaderLoader.displayName = 'AppHeaderLoader'
