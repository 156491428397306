import {
  type PaymentMethodType,
  PaymentMethodTypes,
} from '@webapp/models/enums'
import messages from './messages'
import { useProcurifyIntl } from '../useProcurifyIntl'

export function useFormatPaymentMethodType() {
  const intl = useProcurifyIntl()

  return (paymentMethodType: PaymentMethodType): string => {
    switch (paymentMethodType) {
      case PaymentMethodTypes.OTHER:
        return intl.formatMessage(messages.otherLabel)
      case PaymentMethodTypes.CHECK:
        return intl.formatMessage(messages.externalCheckLabel)
      case PaymentMethodTypes.ACH:
        return intl.formatMessage(messages.externalAchLabel)
      case PaymentMethodTypes.EFT:
        return intl.formatMessage(messages.procurifyEftLabel)
      case PaymentMethodTypes.WIRE:
        return intl.formatMessage(messages.externalWireLabel)
      case PaymentMethodTypes.PROCURIFY_WIRE:
        return intl.formatMessage(messages.procurifyWireLabel)
      case PaymentMethodTypes.PROCURIFY_CHECK:
        return intl.formatMessage(messages.procurifyCheckLabel)
      case PaymentMethodTypes.PROCURIFY_ACH:
        return intl.formatMessage(messages.procurifyAchLabel)
      default:
        return null
    }
  }
}
