import * as React from 'react'

import {
  CardMetaStyled,
  type ICardMetaStyledProps,
} from './styles/CardMetaStyled'

export interface ICardMetaProps extends ICardMetaStyledProps {}

export const CardMeta = ({
  children,
  ...props
}: React.PropsWithChildren<ICardMetaProps>) => (
  <CardMetaStyled {...props}>{children}</CardMetaStyled>
)

CardMeta.displayName = 'CardMeta'
