import { shade } from 'polished'
import { css } from 'styled-components'

export const applyButtonColorCSS = (
  themeColor,
  borderColor?,
  textColor?,
  hoverBackgroundColor?,
  hoverBorderColor?
) => {
  return css`
    color: ${(props) => textColor || props.theme.colors.button.base.inverse};
    background-color: ${themeColor};
    border-color: ${borderColor || themeColor};

    &:visited {
      color: ${(props) => textColor || props.theme.colors.button.base.inverse};
    }

    &:hover {
      color: ${(props) => textColor || props.theme.colors.button.base.inverse};
      background-color: ${hoverBackgroundColor || shade(0.1, themeColor)};
      border-color: ${hoverBorderColor ||
      shade(0.1, borderColor || themeColor)};
    }

    &:active {
      color: ${(props) => textColor || props.theme.colors.button.base.inverse};
      background-color: ${shade(0.2, themeColor)};
      border-color: ${shade(0.2, borderColor || themeColor)};
    }
  `
}
