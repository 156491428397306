import * as React from 'react'

import { type PaletteProps } from '@material-ui/system'

import { HeaderStepStyled } from './styles/HeaderStepStyled'

export interface IHeaderStepProps extends Pick<PaletteProps, 'color'> {}

export const HeaderStep = ({
  children,
  color,
}: React.PropsWithChildren<IHeaderStepProps>) => {
  return <HeaderStepStyled color={color}>{children}</HeaderStepStyled>
}
