import * as React from 'react'

import styled from 'styled-components'

import { useProcurifyIntl } from '@webapp/hooks'
import messages from '../messages'

// tslint:disable-next-line: no-var-requires
const procurifyLogo = require('!svg-url-loader!../../../assets/img/procurify-logo.svg')

export const AppHeaderLogo = styled((props) => {
  const intl = useProcurifyIntl()
  return (
    <a {...props} href='/'>
      <img
        src={procurifyLogo}
        height={30}
        aria-label={intl.formatMessage(messages.appLogoLabel)}
      />
    </a>
  )
})`
  display: block;
  height: ${(props) => props.theme.typography.pxToRem(50)};
  line-height: ${(props) => props.theme.typography.pxToRem(50)};
  width: ${(props) => props.theme.typography.pxToRem(115)};
  text-align: center;

  > img {
    vertical-align: middle;
  }
`
