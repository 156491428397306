import { Enum } from '../../utils/enums'

export const TravelItemStatusEnumValues = [
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied',
]
export const TravelItemStatus = Enum(
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied'
)
