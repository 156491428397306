import * as React from 'react'

import { CardContent } from './components/CardContent'
import { CardFooter } from './components/CardFooter'
import { CardHeader } from './components/CardHeader'
import { CardMeta } from './components/CardMeta'
import { CardStyled, type ICardStyledProps } from './styles/CardStyled'

export interface ICardProps extends ICardStyledProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export interface ICard
  extends React.ForwardRefExoticComponent<
    React.PropsWithChildren<ICardProps> & React.RefAttributes<HTMLDivElement>
  > {
  Content: typeof CardContent
  Footer: typeof CardFooter
  Header: typeof CardHeader
  Meta: typeof CardMeta
}

export const Card = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ICardProps>
>(({ children, ...props }, ref) => (
  <CardStyled {...props} ref={ref}>
    {children}
  </CardStyled>
)) as ICard

Card.Content = CardContent
Card.Footer = CardFooter
Card.Header = CardHeader
Card.Meta = CardMeta

Card.displayName = 'Card'
