import { type Enum } from '@webapp/utils'

export const LoggerActions = {
  REQUEST: 0,
  FINAL_APPROVE: 1,
  DENY: 2,
  APPROVE_AND_PASS_ON: 3,
} as const

export type LoggerAction = Enum<typeof LoggerActions>
