import * as React from 'react'

import { BaseTextStyled } from './styles/BaseTextStyled'

export interface IBaseTextProps {
  id?: string
  inline?: boolean
  gutter?: boolean
  autoMargin?: boolean

  align?: 'left' | 'right' | 'center' | 'justify'

  breakWord?: boolean
  noWrap?: boolean
  textEllipsis?: boolean

  italic?: boolean

  bold?: boolean

  color?: string
  bgcolor?: string

  /** Styled-components is preferred for targeting element's styles. This is only to support backwards compatibility.  */
  className?: string
}

export const BaseText = ({
  autoMargin = true,
  inline,
  ...props
}: React.PropsWithChildren<IBaseTextProps>) => {
  return (
    <BaseTextStyled
      as={inline ? 'span' : 'p'}
      inline={inline}
      autoMargin={autoMargin}
      {...props}
    />
  )
}
