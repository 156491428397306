import { palette, sizing, spacing } from '@material-ui/system'
import styled from 'styled-components'

import {
  type PaletteProps,
  type SizingProps,
  type SpacingProps,
} from '@ui/core/types'

export interface ICardContentStyled
  extends Pick<PaletteProps, 'bgcolor'>,
    Pick<SpacingProps, 'p' | 'pt' | 'pr' | 'pb' | 'pl' | 'px' | 'py'>,
    Pick<SizingProps, 'height' | 'minHeight' | 'maxHeight'> {}

export const CardContentStyled = styled.div<ICardContentStyled>`
  color: ${(props) => props.theme.colors.text.primary};

  padding: ${(props) =>
    props.theme.box.horizontalPadding(props.theme.typography.htmlFontSize)};

  & + & {
    padding-top: 0;
  }

  ${palette};
  ${spacing};
  ${sizing};
`
CardContentStyled.displayName = 'CardContentStyled'
