import { type Enum } from '@webapp/utils'

export const SettingsBillFormControlTypes = {
  BILL_FIELD_INVOICE_NUMBER_REQUIRED: 'bill_field_invoice_number_required',
  VERIFIED_THREEWAY_WAY_MATCHING: 'verified_three_way_matching',
}

export type SettingsBillFormControlType = Enum<
  typeof SettingsBillFormControlTypes
>
