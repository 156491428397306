import messages from './messages'
import { useProcurifyIntl } from '../useProcurifyIntl'

/**
 * This function returns width values used to display the current spend, current request spend and other pending request spend values as a percentage relative to the current spend.
 * If the current spend or the cumulative sum of current spend, current request spend and other pending request spend values does not exceed 100, then the width values returned will
 * be represented as a percentage of 100 as expected.
 * i.e. if the budget is 2000, current spend is 1000, current request is 300 and other pending requests is 500, then the respective values returned are 50, 15, and 25
 *
 * The values will not be representative if the total sum of current spend, current request spend and other pending request spend exceeds 100. i.e.
 * if the budget is 2000, current spend is 1600, current request is 300 and other pending requests is 500, then the respective values returned are 80, 15, and 5
 * if the budget is 2000, current spend is 1900, current request is 300 and other pending requests is 500, then the respective values returned are 95, and 5
 *
 * In other words, the values for current request spend and other pending request spend will always be the remainder of the available width remaining from the current spend value.
 * Visual examples can be found in Storybook and in PR #3148
 *
 * @param currentSpend The total spend (approved, purchased, invoiced) that match the relevant Spend Category i.e. the sum of approved, purchased and invoiced spend totalling 1600
 * @param currentRequestSpend The spend associated with the current request i.e. an RFO requesting to spend 300
 * @param otherPendingRequestSpend The spend associated with other pending spend that matches the relevant Spend Category attributes i.e. a separate RFO requesting to spend 500
 * @param budget The total budget allotment for the relevant Spend Category i.e. a buget amount of 2000
 * @returns {Object} An object containing the multi progress bar width values
 */
export const getBudgetProgressValuesRelativeToCurrentSpend = (
  currentSpend: number,
  currentRequestSpend: number,
  otherPendingRequestSpend: number,
  budget: number
): {
  currentSpendProgressValue: number
  currentRequestSpendProgressValue: number
  otherPendingRequestSpendProgressValue?: number
} => {
  let remainingBudgetWidth = 100

  const currentSpendProgressValue = (currentSpend / budget) * 100

  remainingBudgetWidth -= currentSpendProgressValue

  let currentRequestSpendProgressValue = (currentRequestSpend / budget) * 100

  if (currentRequestSpendProgressValue > remainingBudgetWidth) {
    currentRequestSpendProgressValue = remainingBudgetWidth

    return {
      currentSpendProgressValue,
      currentRequestSpendProgressValue,
    }
  }

  remainingBudgetWidth = Math.max(
    remainingBudgetWidth - currentRequestSpendProgressValue,
    0
  )

  let otherPendingRequestSpendProgressValue =
    (otherPendingRequestSpend / budget) * 100

  if (otherPendingRequestSpendProgressValue > remainingBudgetWidth) {
    otherPendingRequestSpendProgressValue = remainingBudgetWidth
  }

  return {
    currentSpendProgressValue,
    currentRequestSpendProgressValue,
    otherPendingRequestSpendProgressValue,
  }
}

export interface BudgetBreakdownBar {
  label: string
  progressValue: number
  color: string
  striped?: boolean
}

export const useGetBudgetBreakdown = () => {
  const intl = useProcurifyIntl()

  return (
    budgetedSpend: number,
    currentSpend: number,
    currentRequestSpend: number,
    spendAfterApproval: number,
    otherPendingRequests: number,
    approvedSpend?: number,
    purchasedSpend?: number,
    invoicedSpend?: number,

    showSpendBreakdown?: boolean
  ): BudgetBreakdownBar[] => {
    const isOverBudget = spendAfterApproval >= budgetedSpend

    if (showSpendBreakdown) {
      const approvedSpendProgressValue = (approvedSpend / budgetedSpend) * 100
      const purchasedSpendProgressValue = (purchasedSpend / budgetedSpend) * 100
      const billedSpendProgressValue = (invoicedSpend / budgetedSpend) * 100
      const otherPendingRequestSpendProgressValue =
        (otherPendingRequests / budgetedSpend) * 100

      return [
        {
          label: intl.formatMessage(messages.approvedSpendBarLabel),
          progressValue: approvedSpendProgressValue,
          color: !isOverBudget ? 'darkGreen' : 'red',
        },
        {
          label: intl.formatMessage(messages.purchasedSpendBarLabel),
          progressValue: purchasedSpendProgressValue,
          color: !isOverBudget ? 'blue' : 'red',
        },
        {
          label: intl.formatMessage(messages.billedSpendBarLabel),
          progressValue: billedSpendProgressValue,
          color: !isOverBudget ? 'purple' : 'red',
        },
        {
          label: intl.formatMessage(messages.otherPendingRequestsBarLabel),
          progressValue: otherPendingRequestSpendProgressValue,
          color: 'yellow',
        },
      ]
    } else {
      const currentSpendProgressValue = (currentSpend / budgetedSpend) * 100
      const currentRequestSpendProgressValue =
        (currentRequestSpend / budgetedSpend) * 100
      const otherPendingRequestSpendProgressValue =
        (otherPendingRequests / budgetedSpend) * 100

      return [
        {
          label: intl.formatMessage(messages.currentSpendBarLabel),
          progressValue: currentSpendProgressValue,
          color: !isOverBudget ? 'darkGreen' : 'red',
        },
        {
          label: intl.formatMessage(messages.currentRequestBarLabel),
          progressValue: currentRequestSpendProgressValue,
          color: !isOverBudget ? 'darkGreen' : 'red',
          striped: true,
        },
        {
          label: intl.formatMessage(messages.otherPendingRequestsBarLabel),
          progressValue: otherPendingRequestSpendProgressValue,
          color: 'yellow',
        },
      ]
    }
  }
}
