import { createSelector } from 'reselect'

import { initialState } from './reducers'
import { type IGlobalLocaleState } from './types'

const selectLocale = (state: IGlobalLocaleState) => state.i18n || initialState

export const makeSelectLocale = () =>
  createSelector(selectLocale, (state) => state.locale)

export const makeSelectFullLocale = () =>
  createSelector(selectLocale, (state) => state.fullLocale)

export const makeSelectDecimalSeparator = () =>
  createSelector(selectLocale, (state) => state.decimalSeparator)

export const makeSelectThousandSeparator = () =>
  createSelector(selectLocale, (state) => state.thousandSeparator)
