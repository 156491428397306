import { createTheme } from '@material-ui/core/styles'
import { rem } from 'polished'

import {
  extendedTheme,
  getThemeTypographyOptions,
  muiThemeOptions,
  type ThemeInterface,
} from '../default'

const BASE_FONT_SIZE = 12

export const theme = createTheme(
  {
    ...muiThemeOptions,
    spacing: 16,

    typography: getThemeTypographyOptions(BASE_FONT_SIZE),
  },
  {
    ...extendedTheme,
    space: {
      xsmall: rem(4, BASE_FONT_SIZE), // 0.25
      small: rem(8, BASE_FONT_SIZE), // 0.5
      medium: rem(16, BASE_FONT_SIZE), // 1
      large: rem(24, BASE_FONT_SIZE), // 1.5
    },
    appHeader: {
      height: rem(72, BASE_FONT_SIZE),
    },
  }
) as ThemeInterface
