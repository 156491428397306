import * as React from 'react'

import {
  Card,
  Divider,
  Flex,
  Header,
  PrimaryButton,
  SubText,
  TextButton,
} from '@procurify/ui'
import { useIntl } from 'react-intl'

import Logo from './assets/logo-horizontal-64.png'
import messages from './messages'
import { IntermediateLoginStyled } from './styles/IntermediateLoginStyled'

export interface IIntermediateLoginProps {
  tenant: string
  authorizeUrl: string
  ssoAuthorizeUrl: string
}

export const IntermediateLogin = ({
  tenant,
  authorizeUrl,
  ssoAuthorizeUrl,
}: IIntermediateLoginProps) => {
  const intl = useIntl()

  return (
    <IntermediateLoginStyled>
      <Card width={550}>
        <Card.Header>
          <Flex.Row flexDirection='column' alignItems='center'>
            <Flex.Item>
              <img className='image-logo' src={Logo} />
            </Flex.Item>
            <Flex.Item mt='1.25'>
              <Header as='h5' color='white'>
                {tenant.toUpperCase()}
              </Header>
            </Flex.Item>
          </Flex.Row>
        </Card.Header>
        <Card.Content>
          <Flex>
            <Flex.Row
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <Flex.Item>
                <a href={ssoAuthorizeUrl}>
                  <PrimaryButton>
                    {intl.formatMessage(messages.SSOLogin)}
                  </PrimaryButton>
                </a>
              </Flex.Item>
              <Flex.Item width={1}>
                <Flex.Row alignItems='center'>
                  <Flex.Item flexGrow={1}>
                    <Divider space={1 / 4} />
                  </Flex.Item>
                  <Flex.Item>
                    <SubText bold>
                      {intl
                        .formatMessage(messages.dividerCaption)
                        .toLocaleUpperCase()}
                    </SubText>
                  </Flex.Item>
                  <Flex.Item flexGrow={1}>
                    <Divider space={1 / 4} />
                  </Flex.Item>
                </Flex.Row>
              </Flex.Item>
              <Flex.Item>
                <a href={authorizeUrl}>
                  <TextButton>
                    {intl.formatMessage(messages.basicAuthLogin)}
                  </TextButton>
                </a>
              </Flex.Item>
            </Flex.Row>
          </Flex>
        </Card.Content>
      </Card>
    </IntermediateLoginStyled>
  )
}
IntermediateLogin.displayName = 'IntermediateLogin'
