import { shade, tint, transparentize } from 'polished'

// Primary
export const white = '#FFFFFF'
export const darkGreen = '#002E33'

// Secondary
const green = '#31936A'
export const red = '#BC5750'
export const yellow = '#FAAB18'

// Tertiary
export const aquamarine = '#D2FBEF'
export const blue = '#45ACE4'
export const purple = '#8886E9'
const teal = '#26BCB9'

// Variations
const darkGreenTint20 = tint(0.2, darkGreen)
const darkGreenShade50 = shade(0.5, darkGreen)

const greenTint30 = tint(0.3, green)
const greenTint60 = tint(0.6, green)
const greenShade20 = shade(0.2, green)

const aquamarineTint50 = tint(0.5, aquamarine)
const aquamarineShade20 = shade(0.2, aquamarine)

const redTint30 = tint(0.3, red)
const redTint60 = tint(0.6, red)
const redTint90 = tint(0.9, red)
const redShade20 = shade(0.2, red)

const blueTint50 = tint(0.5, blue)
const blueTint90 = tint(0.9, blue)
export const blueShade30 = shade(0.3, blue)

const yellowTint50 = tint(0.5, yellow)
const yellowTint90 = tint(0.9, yellow)

const purpleTint70 = tint(0.7, purple)
const purpleShade30 = shade(0.3, purple)

export const transparent = 'transparent'

// GreyScale
const whiteShade75 = '#404040' // shade(0.75, white)
const whiteShade70 = '#4D4D4D' // shade(0.70, white)
const whiteShade60 = '#666666' // shade(0.6, white)
const whiteShade50 = '#808080' // shade(0.5, white)
const whiteShade40 = '#999999' // shade(0.4, white)
const whiteShade30 = '#B3B3B3' // shade(0.3, white)
const whiteShade20 = '#CCCCCC' // shade(0.2, white)
const whiteShade5 = '#F2F2F2' // shade(0.05, white)
const whiteShade2 = '#FAFAFA' // shade(0.02, white)

// LEGACY Greys
export const darkGrey = whiteShade75
export const lightGrey = '#BFBFBF'
export const offwhite = '#F8F8FA'
const disabledGrey = '#E3E3E3'
const hoverGrey = '#ECECEE'

// LEGACY TOKENS - Content
export const background = offwhite
export const contentBackground = white

// LEGACY TOKENS - Text
export const textPrimary = darkGreenShade50
export const textSecondary = whiteShade75
export const textInverse = white
export const textDisabled = lightGrey

// LEGACY TOKENS - Progress
export const progressPositive = greenTint30
export const progressNegative = redTint30

// Contextual
export const primary = darkGreen
export const secondary = yellow
export const selected = aquamarineTint50
export const success = green
export const danger = red
export const warning = yellow
export const caution = yellow
export const info = blue
export const disabled = lightGrey
export const error = red
export const focus = aquamarine
export const positive = green
export const ai = purpleTint70

// TOKENS - Content
export const content = {
  border: lightGrey,
  mainBackground: offwhite,
  contentBackground: white,
}

// TOKENS - Text
export const text = {
  primary: darkGreenShade50,
  secondary: whiteShade75,
  inverse: white,
  disabled: lightGrey,
  placeholder: whiteShade75,
  link: blueShade30,
  error: red,
  highlight: aquamarine,
}

// TOKENS - Buttons
export const button = {
  base: {
    dark: darkGreen,
    inverse: white,
    tertiary: blueShade30,
  },
  primary: {
    background: darkGreen,
    backgroundHover: darkGreenTint20,
  },
  secondary: {
    background: white,
    backgroundHover: whiteShade5,
    border: darkGreen,
    borderHover: darkGreen,
    text: darkGreen,
  },
  tertiary: {
    background: white,
    backgroundHover: whiteShade5,
    text: text.link,
  },
  request: {
    background: aquamarine,
    backgroundHover: aquamarineShade20,
  },
  approve: {
    background: green,
    backgroundHover: greenShade20,
  },
  deny: {
    background: red,
    backgroundHover: redShade20,
  },
  text: {
    content: text.link,
    contentHover: blue,
  },
  transparentIcon: {
    content: darkGreen,
    contentHover: transparentize(0.5, whiteShade20),
  },
}

// TOKENS - Form Fields
export const form = {
  field: {
    border: whiteShade20,
    borderActive: darkGreen,
    borderError: red,
    disabled: disabledGrey,
  },
  select: {
    optionBackgroundHover: whiteShade5,
    optionBackgroundActive: aquamarineTint50,
  },
  radioCardGroup: {
    border: content.border,
    activeBorder: darkGreen,
    disabledBorder: whiteShade20,
  },
}

// TOKENS - Table
export const table = {
  hover: hoverGrey,
}

// TOKENS - Tabs
export const tabs = {
  contentActive: darkGreen,
  contentInactive: whiteShade60,
  divider: lightGrey,
}

// TOKENS - Status Label
const statusLabelSuccess = tint(0.6, '#03BA5B')
const statusLabelError = tint(0.6, '#D14229')

export const statusLabel = {
  success: {
    background: statusLabelSuccess,
    content: text.primary,
  },
  error: {
    background: statusLabelError,
    content: text.primary,
  },
  warning: {
    background: yellowTint50,
    content: text.primary,
  },
  info: {
    background: blueTint50,
    content: text.primary,
  },
  inactive: {
    background: whiteShade5,
    content: text.primary,
  },
  new: {
    background: aquamarine,
    content: text.primary,
  },
  ai: {
    background: ai,
    content: text.primary,
  },
}

// TOKENS - Alert
export const alert = {
  error: {
    background: redTint90,
    border: error,
    content: textPrimary,
  },
  info: {
    background: blueTint90,
    border: info,
    content: textPrimary,
  },
  warning: {
    background: yellowTint90,
    border: warning,
    content: textPrimary,
  },
  ai: {
    background: ai,
    border: purpleShade30,
    content: textPrimary,
  },
}
