import styled from 'styled-components'

export const NavigationLoaderStyled = styled.div`
  height: ${(props) => props.theme.typography.pxToRem(40)};
  width: 100%;
  display: inline-flex;
  .nav-icon {
    min-width: ${(props) => props.theme.typography.pxToRem(50)};
    height: ${(props) => props.theme.typography.pxToRem(50)};
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
      width: 100%;
    }
  }
  .nav-text {
    height: ${(props) => props.theme.typography.pxToRem(50)};
    display: flex;
    flex-grow: 1;
    padding-right: ${(props) => props.theme.space.medium};
    align-items: center;

    @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
      display: none;
    }
  }
`
