import styled from 'styled-components'

import { IconStyled } from '@ui/elements/Icon'
import { IconComponentStyled } from '@ui/elements/Icon/styles/IconComponentStyled'

export const HeaderIconStyled = styled.div`
  ${IconStyled}, ${IconComponentStyled} {
    display: block;
    font-size: 2em;
    height: 1em;
    width: 1em;

    margin: 0 auto;
    margin-bottom: 0.25em;
  }
`
