import { type Enum } from '@webapp/utils'

/**
 * Previously `PaymentSourceType`, but renamed since it's used to differentiate financial accounts
 * and does not include other (ie. manually tracked) payment sources
 */

export const FinancialAccountTypes = {
  /**
   * @deprecated - to be removed with FT_4281_US_PAYMENT_RAILS; previously used to denote Stripe USD
   *
   */
  USD: 'USD',
  /**
   * @@deprecated - to be removed with FT_4281_US_PAYMENT_RAILS
   *
   */
  CAD: 'CAD',
  AIRWALLEX_USD: 'AIRWALLEX_USD',
  AIRWALLEX_CAD: 'AIRWALLEX_CAD',
  STRIPE_USD: 'STRIPE_USD',
} as const

export type FinancialAccountType = Enum<typeof FinancialAccountTypes>
