import { produce } from 'immer'

import {
  UPDATE_COUNTS,
  UPDATE_LAST_TAB,
  UPDATE_SHOW_BUDGET_PENDING_AMOUNTS,
} from './constants'
import { type IPayApprovalsStoreState, type PayApprovalsActions } from './types'

export const initialState: IPayApprovalsStoreState = {
  tabCounts: {
    all: 0,
    approved: 0,
    pending: 0,
    denied: 0,
  },
  lastTab: 'all',
  showBudgetPendingAmounts: false,
}

export const reducer = (state = initialState, action: PayApprovalsActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_COUNTS:
        draft.tabCounts = {
          ...draft.tabCounts,
          ...action.counts,
        }
        break
      case UPDATE_LAST_TAB:
        draft.lastTab = action.tab
        break
      case UPDATE_SHOW_BUDGET_PENDING_AMOUNTS:
        draft.showBudgetPendingAmounts = action.show
        break
    }
  })
