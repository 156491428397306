import { type Enum } from '@webapp/utils'

export const PunchOutStatuses = {
  ORDER_PLACED: 0,
  ORDER_CANCELLED: 1,
  PARTIALLY_PURCHASED: 2,
  BACKORDERED: 3,
} as const

export type PunchOutStatus = Enum<typeof PunchOutStatuses>
