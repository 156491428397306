import { compose, style, type StyleFunction } from '@material-ui/system'

export type StyleFunctionWithOptionalProps<PropKey extends keyof any> =
  StyleFunction<Partial<Record<PropKey, any>>>

export const color = style({
  prop: 'color',
  themeKey: 'colors',
}) as StyleFunctionWithOptionalProps<'color'>

export const bgcolor = style({
  cssProperty: 'backgroundColor',
  prop: 'bgcolor',
  themeKey: 'colors',
}) as StyleFunctionWithOptionalProps<'bgcolor'>

export const palette = compose(color, bgcolor)
