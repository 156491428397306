import { style } from '@material-ui/system'
import {
  extendedTheme,
  palette,
  type StyleFunctionWithOptionalProps,
  textEllipsis,
} from '@procurify/ui-theme'
import styled, { css } from 'styled-components'

import { type IBaseTextProps } from '../BaseText'

const gutter = style({
  cssProperty: 'marginBottom',
  prop: 'gutter',
  transform: (value) => (value ? '1rem' : null),
}) as StyleFunctionWithOptionalProps<'gutter'>

const breakWord = style({
  cssProperty: 'wordBreak',
  prop: 'breakWord',
  transform: () => 'break-word',
}) as StyleFunctionWithOptionalProps<'breakWord'>

const inline = style({
  cssProperty: 'display',
  prop: 'inline',
  transform: () => 'inline-block',
}) as StyleFunctionWithOptionalProps<'inline'>

const bold = style({
  cssProperty: 'fontWeight',
  prop: 'bold',
  transform: (value) =>
    value ? extendedTheme.fontWeights.bold : extendedTheme.fontWeights.normal,
}) as StyleFunctionWithOptionalProps<'bold'>

export const BaseTextStyled = styled.p<IBaseTextProps>`
  margin: 0;
  text-decoration: none;
  text-align: ${(props) => props.align};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  white-space: ${(props) => (props.noWrap ? 'nowrap' : 'normal')};

  & + & {
    margin-top: ${(props) =>
      props.autoMargin && !props.inline
        ? props.theme.typography.pxToRem(8)
        : 0};
  }

  ${inline}
  ${gutter}
  
  ${breakWord}
  ${textEllipsis}

  ${bold}

  ${palette}
`
