import * as React from 'react'

import { FlexItemStyled } from './styles/FlexItemStyled'
import { type SizingProps, type SpacingProps } from '@ui/core/types'

export interface IFlexItemProps extends SizingProps, SpacingProps {
  children?: React.ReactNode
  /**
   * If `none`, the component is removed from the layout, including the
   * accessibility tree.
   */
  display?: 'block' | 'none'
  /**
   * Sets the outer gutter space around all four sides of the component. Do
   * not manually set this properly on this component as it is meant to be set
   * by a parent flex row component.
   */
  spacing?: number
  /**
   * Sets how the component will grow or shrink to fit the space available in a
   * parent flex row component. This is a shorthand for `flexGrow`,
   * `flexShrink` and `flexBasis` props.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/flex
   */
  flex?: number | string
  /**
   * Sets the initial main size of the component. The main size is the width
   * or height of the component, depending on the parent flex row component
   * main direction.
   */
  flexBasis?:
    | number
    | 'auto'
    | 'content'
    | 'fill'
    | 'max-content'
    | 'min-content'
    | 'fit-content'
  /**
   * Sets the flex grow factor of the component main size. The main size
   * is the width or height of the component, depending on the parent flex row
   * component main direction.
   */
  flexGrow?: number
  /**
   * Sets the flex shrink factor of the component main size. If the size of all
   * flex items is larger than the flex container, items shrink to fit according
   * to flexShrink.
   */
  flexShrink?: number
  /**
   * Overrides the `alignItems` prop value and aligns the component on the
   * parent flex row component cross axis.
   */
  alignSelf?:
    | 'auto'
    | 'baseline'
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'stretch'
  /**
   * Sets the order to lay out the component in a flex row component.
   * The component will be sorted by ascending order value and then by its order
   * in source code.
   */
  order?: number
}

export const FlexItem = ({ spacing = 0, ...props }: IFlexItemProps) => (
  <FlexItemStyled spacing={spacing} {...props} />
)

FlexItem.displayName = 'FlexItem'
