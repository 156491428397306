import * as React from 'react'

import {
  CardHeaderStyled,
  type ICardHeaderStyledProps,
} from './styles/CardHeaderStyled'

export interface ICardHeaderProps extends ICardHeaderStyledProps {}

export const CardHeader = ({
  children,
  ...props
}: React.PropsWithChildren<ICardHeaderProps>) => (
  <CardHeaderStyled {...props}>{children}</CardHeaderStyled>
)

CardHeader.displayName = 'CardHeader'
