import styled, { css } from 'styled-components'

import { type IFlexProps } from '../Flex'

export const FlexStyled = styled.div<IFlexProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${(props) => {
    if (props.spacing <= 0) return {}
    const gutter = props.spacing * props.theme.spacing()
    return css`
      padding: ${props.theme.typography.pxToRem(gutter / 2)};
    `
  }}
`
