import styled from 'styled-components'

export const CardFooterStyled = styled.div`
  color: ${(props) => props.theme.colors.text.primary};

  padding: ${(props) =>
    props.theme.box.horizontalPadding(props.theme.typography.htmlFontSize)};
`

CardFooterStyled.displayName = 'CardFooterStyled'
