import { Enum } from '../../utils/enums'

export const StripeFundingStatus = Enum(
  'canceled',
  'failed',
  'pending',
  'reversed',
  'succeeded'
)

export type StripeFundingStatus = Enum<typeof StripeFundingStatus>
