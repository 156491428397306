import { Enum } from '../../utils/enums'

export const OrderStatusEnumValues = [
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied',
  'Purchased',
  'Received',
]
export const OrderStatus = Enum(
  'Created',
  'Edited',
  'Deleted',
  'Submitted',
  'Approved',
  'Denied',
  'Purchased',
  'Received'
)

export enum OrderStatusEnum {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = 2,
  PURCHASED = 3,
  /**
   * Not used
   */
  CANCELLED = 4,
  RECEIVED = 5,
  DRAFT = 6,
}
