import styled from 'styled-components'

export interface INotificationButtonProps {}

export const NotificationButtonStyled = styled.button<INotificationButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;

  padding: ${(props) => props.theme.space.xsmall};

  pointer-events: auto;
  cursor: pointer;
`
