export enum PermissionGroupEnum {
  GENERAL = 1,
  EKA = 2,
  SUPERUSER = 3,
  REQUESTER = 4,
  APPROVER = 5,
  PURCHASER = 6,
  RECEIVER = 7,
  CONTROLLER = 8,
  LOCATION_MANAGER = 9,
  REPORT = 10,
  AP = 11,
  AP_APPROVER = 12,
  ACCOUNTS_PAYABLE = 13,
  'APPROVER/RECEIVER' = 14,
}
