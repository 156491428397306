import * as React from 'react'

import { TaxAPI } from '@webapp/api/TaxAPI'
import { type TaxFormSchema } from '@webapp/components/TaxForm/TaxForm'
import { type Tax } from '@webapp/models'

export const useActiveTaxes = () => {
  const [activeTaxes, setActiveTaxes] = React.useState<Tax[]>(null)

  const getActiveTaxes = async () => {
    const { data: taxes } = await TaxAPI.getActiveTaxes({
      page_size: 999,
    })

    setActiveTaxes(taxes)

    return taxes
  }

  const createActiveTax = async (data: TaxFormSchema) => {
    const { data: newTax } = await TaxAPI.createTax({
      name: data.name,
      is_inclusive: data.isInclusive,
      description: data.description,
      percentage: (data.percentageFloatValue / 100).toFixed(6),
    })

    addActiveTax(newTax)

    return newTax
  }

  const addActiveTax = (newTax: Tax) => {
    setActiveTaxes([newTax, ...activeTaxes])
  }

  return {
    activeTaxes,
    getActiveTaxes,
    createActiveTax,
    addActiveTax,
  }
}
