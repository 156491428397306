import { type Enum } from '@webapp/utils'

export const ContractStatuses = {
  DRAFT: 0,
  INACTIVE: 1,
  ACTIVE: 2,
  EXPIRED: 3,
  TERMINATED: 4,
  PROCESSING: 5,
  AI_DRAFT: 6,
} as const

export type ContractStatus = Enum<typeof ContractStatuses>
