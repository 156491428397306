import { DisplayProps, SpacingProps } from '@material-ui/system'

import { type IBaseButtonProps } from './BaseButton'
import { TextButtonStyled } from './styles/TextButtonStyled'

export interface ITextButtonProps extends IBaseButtonProps {}

export const TextButton: React.FC<ITextButtonProps> = TextButtonStyled

TextButton.displayName = 'TextButton'
