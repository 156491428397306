import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { formatDecimals } from '@webapp/core/utils/formatDecimals'
import { makeSelectQuantityDecimalPlaces } from '@webapp/stores/global'
import { makeSelectThousandSeparator } from '@webapp/stores/i18n'

interface UseFormatQuantityOptions {
  mdp: boolean
  decimalPlaces?: number
}

const DEFAULT_OPTIONS: UseFormatQuantityOptions = {
  mdp: false,
}

export const useFormatQuantity = (opts?: Partial<UseFormatQuantityOptions>) => {
  const intl = useIntl()
  const quantityDecimalPlaces = useSelector(makeSelectQuantityDecimalPlaces())
  const thousandSeparator = useSelector(makeSelectThousandSeparator())

  const options = {
    ...DEFAULT_OPTIONS,
    ...opts,
  }

  return (quantity: string | number) => {
    try {
      return (
        intl
          .formatNumber(formatDecimals(quantity).toNumber(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: options.mdp
              ? options.decimalPlaces || quantityDecimalPlaces
              : 2,
          })
          // Quantity doesnt need thousand separators
          .replace(new RegExp(`\\${thousandSeparator}`, 'g'), '')
      )
    } catch (e) {
      return '0'
    }
  }
}
