import { defineMessages } from 'react-intl'

const scope = `procurify.hooks.useFormatPaymentSourceType`

export default defineMessages({
  otherLabel: {
    id: `${scope}.otherLabel`,
    defaultMessage: 'Other',
  },
  externalCheckLabel: {
    id: `${scope}.externalCheckLabel`,
    defaultMessage: 'External check',
  },
  externalAchLabel: {
    id: `${scope}.externalAchLabel`,
    defaultMessage: 'External ACH',
  },
  procurifyAchLabel: {
    id: `${scope}.procurifyAchLabel`,
    defaultMessage: 'Procurify ACH',
  },
  procurifyEftLabel: {
    id: `${scope}.procurifyEftLabel`,
    defaultMessage: 'Procurify EFT',
  },
})
