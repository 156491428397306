import styled from 'styled-components'

import { boxShadow } from '@ui/utils/boxShadow'

export interface INotificationContainerProps {}

export const NotificationContainerStyled = styled.div<INotificationContainerProps>`
  width: ${(props) => props.theme.typography.pxToRem(368)};

  display: flex;
  align-items: flex-start;

  background: white;
  ${boxShadow()}
  border-radius: 5px;
  padding: ${(props) =>
    `${props.theme.typography.pxToRem(16)} ${props.theme.typography.pxToRem(
      24
    )}`};
`

NotificationContainerStyled.displayName = 'NotificationContainerStyled'
