import { typography } from '@material-ui/system'
import styled, { css, keyframes } from 'styled-components'

import { type IIconComponentProps } from '../types'

export interface IIconComponentStyledProps extends IIconComponentProps {}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const IconComponentStyled = styled.i<IIconComponentStyledProps>`
  display: ${(props) => (props.block ? 'block' : 'inline-block')};

  font-size: ${(props) =>
    props?.size ? props.theme.typography.pxToRem(props.size) : 'inherit'};

  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : 'inherit'};

  line-height: 1;

  > svg {
    display: block;
  }

  ${(props) =>
    props.spin &&
    css`
      > svg {
        animation: ${spin} 2s linear infinite;
      }
    `}

  ${typography};
`
