import { useSelector } from 'react-redux'

import { useProcurifyIntl } from '@webapp/hooks/useProcurifyIntl'
import {
  type PaymentSourceType,
  PaymentSourceTypes,
} from '@webapp/models/enums/PaymentSourceType'
import { makeSelectFeatureSwitches } from '@webapp/stores/global'
import messages from './messages'

export function useFormatPaymentSourceType() {
  const intl = useProcurifyIntl()

  const { canadian_bill_payments } = useSelector(makeSelectFeatureSwitches())

  return (paymentSourceType: PaymentSourceType): string => {
    switch (paymentSourceType) {
      case PaymentSourceTypes.OTHER:
        return intl.formatMessage(messages.otherLabel)
      case PaymentSourceTypes.EXTERNAL_CHECK:
        return intl.formatMessage(messages.externalCheckLabel)
      case PaymentSourceTypes.EXTERNAL_ACH:
        return intl.formatMessage(messages.externalAchLabel)
      case PaymentSourceTypes.FINANCIAL_ACCOUNT:
        return canadian_bill_payments
          ? intl.formatMessage(messages.procurifyEftLabel)
          : intl.formatMessage(messages.procurifyAchLabel)
      default:
        return null
    }
  }
}
